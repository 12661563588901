import PropTypes from 'prop-types';
import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UsersRole } from '../../utility/utility';

const Dashboard = lazy(() => import('../../container/pages/Dashboard'));
const Organization = lazy(() => import('../../container/pages/Organizations'));
const ResetPassword = lazy(() => import('../../container/profile/authentication/overview/ResetPassword'));
const Tpa = lazy(() => import('../../container/pages/Tpa'));
const Insurance = lazy(() => import('../../container/pages/Insurance'));
const Claims = lazy(() => import('../../container/pages/Claims'));
const Settings = lazy(() => import('../../container/pages/Settings'));
const OrganizationView = lazy(() => import('../../components/organization-view/OrganizationView'));
const HrOrganizationView = lazy(() => import('../../components/hrOrganizationView/HrOrganizationView'));
const HRManagement = lazy(() => import('../../container/pages/HRManagement'));
const CdStatement = lazy(() => import('../../container/pages/CdStatement'));
const Policy = lazy(() => import('../../container/pages/Policy'));
const PolicySuperAdmin = lazy(() => import('../../container/pages/policySuperAdmin'));
const ClaimList = lazy(() => import('../../container/pages/ClaimList'));
const PolicyForm = lazy(() => import('../../components/policy-form/PolicyForm'));
const PolicyView = lazy(() => import('../../components/policy-view/PolicyView'));
const PolicyNew = lazy(() => import('../../components/policy-view-new/PolicyNew'));

const EmployeePolicyList = lazy(() => import('../../container/pages/EmployeePolicyList'));
const Profile = lazy(() => import('../../container/profile/settings/overview/Profile'));
const ECards = lazy(() => import('../../container/pages/ECards'));
const Rfqs = lazy(() => import('../../container/pages/Rfqs'));
const RfqsList = lazy(() => import('../../container/pages/RfqsList'));
const WellnessOrganizationList = lazy(() => import('../../container/pages/WellnessOrganizationList'));
const WellnessOrganizationForm = lazy(() =>
  import('../../components/wellness-organization-form/WellnessOrganizationForm'),
);
const Instructions = lazy(() => import('../../container/pages/Instructions'));
const RfqForm = lazy(() => import('../../form/RfqForm'));
const Endorsement = lazy(() => import('../../container/pages/Endorsement'));
const AdminEndorsement = lazy(() => import('../../container/pages/AdminEndorsement'));
const EventManagement = lazy(() => import('../../container/pages/EventManagement'));
const EventChats = lazy(() => import('../../container/pages/EventChats'));
const SosList = lazy(() => import('../../container/pages/SosList'));
const AdminEventList = lazy(() => import('../../container/pages/AdminEventList'));
// const NewWellnessActivity = lazy(() => import('../../container/pages/NewWellnessActivity'));
const WellnessDashboard = lazy(() => import('../../components/wellnes-dashboard/WellnessDashboard'));
const WellnessPackages = lazy(() => import('../../components/wellness-packages/WellnessPackages'));
const WellnessActivity = lazy(() => import('../../components/wellness-activity/WellnessActivity'));
const WellnessActivityPackage = lazy(() =>
  import('../../components/wellness-activity-package/WellnessActivityPackages'),
);

const WellnessHealthCheckup = lazy(() => import('../../container/pages/WellnessHealthCheckup'));
const DoctorConsultantions = lazy(() => import('../../components/wellness-activity/DoctorConsultantions'));
const DoctorConsultantionsForm = lazy(() => import('../../container/pages/DoctorConsultationForm'));
const Wellness = lazy(() => import('../../components/wellness-activity/wellness'));
const WellnesActivity = lazy(() => import('../../components/wellness-activity/WellnesActivity'));
const WellnessForm = lazy(() => import('../../components/wellness-activity/WellnessForm'));
// const PolicyNew = lazy(() => import('../../container/pages/PolicyNew'));

function PagesRoute({ collapsed }) {
  const role = useSelector((state) => state?.auth.user?.role);
  return (
    <Routes>
      {/* employee routes */}
      <Route path="policies" element={<EmployeePolicyList />} />
      <Route path="policy-details/:id" element={<PolicyView />} />
      <Route path="profile" element={<Profile />} />
      <Route path="endorsement" element={<Endorsement />} />
      <Route path="endorsement/:id" element={<Endorsement />} />
      {/* primary hr routes */}
      <Route path="hr-dashboard/:id" element={<HrOrganizationView />} />
      {/* {New hr dashboard route} */}
      <Route path="hrdashboard/:id" element={<OrganizationView />} />
      {/* <Route index element={<Dashboard />} /> */}
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="organizations" element={<Organization />} />
      <Route path="resetPassword" element={<ResetPassword />} />
      <Route path="tpas" element={<Tpa />} />
      <Route path="claims" element={<Claims />} />
      <Route path="admin-endorsement" element={<AdminEndorsement />} />
      <Route path="insurance-companies" element={<Insurance />} />
      <Route path="settings" element={<Settings />} />
      <Route path="organizations/:id/HRs" element={<HRManagement />} />
      <Route path="organizations/:id/cd-statement" element={<CdStatement />} />
      <Route path="organizations/:id" element={<OrganizationView />} />
      {/* <Route path="organizations/:id/policy" element={<Policy />} /> */}
      {role === UsersRole.SUPER_ADMIN ? (
        <Route path="organizations/:id/policy" element={<PolicySuperAdmin />} />
      ) : (
        <Route path="organizations/:id/policy" element={<Policy />} />
      )}
      <Route path="organizations/:id/claim" element={<ClaimList />} />
      <Route path="organizations/:id/policy/:id/claim" element={<ClaimList />} />
      <Route path="organizations/:id/policy/add-policy" element={<PolicyForm />} />
      {role === UsersRole.PRIMARY_HR ? (
        <Route path="organizations/:id/policy/:number" element={<PolicyNew collapsed={collapsed} />} />
      ) : (
        <Route path="organizations/:id/policy/:number" element={<PolicyView />} />
      )}
      <Route path="e-cards/:id" element={<ECards />} />
      <Route path="rfqs" element={<Rfqs />} />
      <Route path="rfq-list" element={<RfqsList />} />
      <Route path="rtf-form" element={<RfqForm />} />
      <Route path="instructions" element={<Instructions />} />
      <Route path="wellness-organization" element={<WellnessOrganizationList />} />
      <Route path="wellness-organization/add-wellness-organization" element={<WellnessOrganizationForm />} />
      <Route path="organizations/:id/events" element={<EventManagement />} />
      <Route path="organizations/:id/chat" element={<EventChats />} />
      <Route path="dashboard/event-list" element={<AdminEventList />} />
      <Route path="sos-list" element={<SosList />} />
      {/* <Route path="hr-dashboard/:id/wellness-activity" element={<NewWellnessActivity />} /> */}
      <Route path="wellness-dashboard/:id" element={<WellnessDashboard />} />
      <Route path="organizations/:id/wellnesspackage" element={<WellnessPackages />} />
      <Route path="hr-dashboard/:id/wellness-activity" element={<WellnessActivity />} />
      <Route path="hr-dashboard/:id/wellness-activity-package" element={<WellnessActivityPackage />} />
      <Route path="hr-dashboard/:id/wellness-health-checkup" element={<WellnessHealthCheckup />} />
      <Route path="hr-dashboard/:id/wellness-doctor-consultantion" element={<DoctorConsultantions />} />
      <Route path="hr-dashboard/:id/wellness-doctor-consultantion-form" element={<DoctorConsultantionsForm />} />
      <Route path="hr-dashboard/:id/wellness" element={<Wellness />} />
      <Route path="hr-dashboard/:id/wellnes-activity" element={<WellnesActivity />} />
      <Route path="hr-dashboard/:id/wellness-form" element={<WellnessForm />} />
    </Routes>
  );
}

PagesRoute.propTypes = {
  collapsed: PropTypes.bool,
};

export default PagesRoute;
