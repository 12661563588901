import * as types from './actionTypes';

const initialState = {
  loading: false,
  metaParams: {},
  addCdSuccess: false,
  updateEndorSuccess: false,
  updateCdSuccess: false,
  updateCdAttachmentSuccess: false,
  allCdList: null,
  cdWellnessMetaParams: {},
  cdList: [],
};

const cdReducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case types.ADD_CD_SUCCESS:
      return {
        ...state,
        addCdSuccess: action.payload,
      };
    case types.UPDATE_ENDOR_SUCCESS:
      return {
        ...state,
        cdList: action.payload,
      };

    case types.SET_ALL_CD:
      return {
        ...state,
        cdList: action.payload,
      };
    case types.UPDATE_CD_SUCCESS:
      return {
        ...state,
        updateCdSuccess: action.payload,
      };
    case types.ADD_CD_ENDT_STATEMENT:
      return {
        ...state,
        updateCdAttachmentSuccess: action.payload,
      };
    case types.SET_META_PARAMS:
      return {
        ...state,
        loading: false,
        metaParams: action.payload,
      };
    case types.GET_ALL_CD_LIST:
      return {
        ...state,
        loading: false,
      };
    case types.SET_ALL_CD_LIST:
      return {
        ...state,
        allCdList: action.payload,
      };
    case types.SET_CD_WELLNESS_META_PARAMS:
      return {
        ...state,
        loading: false,
        cdWellnessMetaParams: action.payload,
      };
    default:
      return state;
  }
};

export default cdReducer;
