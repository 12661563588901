/* eslint-disable import/no-cycle */
import { DataService } from '../../../config/dataService/dataService';
import { DataService2 } from '../../../config/dataService/dataServiceSecondary';
import { getQueryString } from '../../../utility/utility';

export const addOrganizationApi = (values) => {
  const formData = new FormData();
  formData.append('companyName', values.name);
  formData.append('companyLogo', values.companyLogo);
  formData.append('panNumber', values.panNumber);
  formData.append('gstNumber', values.gstNumber);
  return DataService.post('/organizations', formData);
};

export const updateOrganizationApi = (values) => {
  const formData = new FormData();
  formData.append('companyName', values.companyName);
  formData.append('panNumber', values.panNumber);
  formData.append('gstNumber', values.gstNumber);
  if (values) {
    formData.append('companyLogo', values.companyLogo);
  }
  formData.append('_method', 'PUT');
  return DataService.post(`/organizations/${values.id}`, formData);
};
export const getAllOrganizationApi = () => {
  return DataService.get('/organizations');
};
export const searchOrganizationApi = (values) => {
  return DataService.get(`/organizations?${getQueryString(values)}`);
};
export const getOrganizationByIdApi = (values) => {
  const Id = values?.data?.id;
  return DataService.get(`/organizations/${Id}`);
};
export const getNextPageOrganizationApi = (values) => {
  return DataService.get(`/organizations?${getQueryString(values)}`);
};

export const getOrganizationListApi = (values) => {
  return DataService.get(`/organization/list?search=${values.data}`);
};

export const getExportOrganizationApi = (value) => {
  return DataService.get(`/organizations/export/csv?status=${value.status}`);
};

export const addCalendarApi = (values) => {
  const payload = {
    orgnizationId: values.orgnization_id,
    createdHrId: values.created_hr_id,
    activityType: values.activityType,
    transactionType: values.transaction,
    description: values.description,
    dateOfActivity: values.date,
    familyDefinitionType: values.familyDefinition,
    eventName: values.eventName,
    sampleCollectionFrom: values.sampleCollection,
    packageId: values.package,
    noOfEmployee: values.noofemp,
    totalCost: values.totalCost,
    budget: values.budget,
  };

  return DataService2.post('/wellness-activity', payload);
};

export const getCalendarApi = (value) => {
  return DataService2.get(`/wellness-activity?page=${value.page}&orgid=${value.orgId}`);
};

export const getEventDateApi = () => {
  return DataService2.get(`/wellness-activity`);
};

export const getwellnessActivityHistoryApi = (value) => {
  return DataService2.get(`/wellness-activity-history/history/${value.id}`);
};

export const addWellnessActivityApi = (values) => {
  const payload = {
    wellness_activity_booking_id: values.wellness_activity_booking_id,
    hr_id: values.hr_id,
    rm_id: values.rm_id,
    amount: values.amount, // optinal
    description: values.description,
    employeeCode: values?.employeeCode,
    employeeMobile: values?.employeeMobile,
    employeeName: values?.employeeName,
    isFinal: values?.isFinal,
  };
  return DataService2.post('/wellness-activity/wellness-history-create', payload);
};

export const getwellnessPackagesApi = () => {
  return DataService2.get(`/wellness-packages`);
};

export const uploadEventRecordApi = (files) => {
  const formData = new FormData();
  // eslint-disable-next-line no-unused-vars
  files.recordFiles.forEach((file, index) => {
    formData.append('files', file, file.name); // appending files with name for clarity
  });
  return DataService2.post(`/wellness-reports/upload-multiple/${files.id}`, formData);
};

export const wellnessHealthCheckupApi = (values) => {
  const payload = {
    orgnizationId: values.orgnizationId,
    createdHrId: values.createdHrId,
    activityType: values.activityType,
    familyDefinition: values.familyDefinition,
    totalEmployee: values.totalEmployee,
    totalLives: values.totalLives,
    costPerPerson: values.costPerPerson,
    date: values.date ?? '',
    time: values.time ?? '',
    city: values.city ?? '',
    pincode: values.pincode ?? '',
    address: values.address ?? '',
    terms: values.terms ?? '',
    activityName: values.activityName ?? '',
    budget: values.budget ?? '',
  };
  return DataService2.post('/wellness-activity', payload);
};

export const wellnessDoctorConsultationApi = (values) => {
  const payload = {
    familyDefinition: values.familyDefinition,
    totalEmployee: values.totalEmployee,
    totalLives: values.totalLives,
    costPerPerson: values.costPerPerson,
    dependents: values.dependents,
    terms: values.terms,
  };
  return DataService2.post('/wellness-activity', payload);
};
