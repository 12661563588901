/* eslint-disable import/no-cycle */

import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import {
  changePasswordApi,
  createMpinApi,
  dashboardKpiApi,
  employeeLoginApi,
  employeeRegisterApi,
  forgotPasswordApi,
  getExportPortfolioApi,
  loginApi,
  logoutApi,
  verifyOtpApi,
} from '../requests/userRequests';
import * as types from '../../authentication/actionTypes';
import {
  loginBegin,
  loginSuccess,
  loginErr,
  logoutBegin,
  logoutSuccess,
  logoutErr,
  setUser,
  forgotPasswordError,
  emailVerified,
  otpVerified,
  forgotPasswordSuccess,
  changePasswordSuccess,
  changePasswordError,
  setMetaParams,
  registerRedirection,
  otpRedirection,
  employeeLoginRedirection,
  isMpinCreated,
  createMpinRedirection,
  setKpi,
} from '../../authentication/actions';
import { removeItem, setItem } from '../../../utility/localStorageControl';
import { startLoader, stopLoader } from '../../../utility/commonFunction';
import { UsersRole, xlsFileConverter } from '../../../utility/utility';

function* loginHandler(values) {
  yield startLoader('transparent');

  yield put(loginBegin());
  try {
    const { data } = yield call(loginApi, values?.payload?.data);
    if (data?.status) {
      yield put(setUser(data?.data?.user));
      if (
        data?.data.user.role === UsersRole.SUPER_ADMIN ||
        data?.data.user.role === UsersRole.CLAIM_CREATOR ||
        data?.data.user.role === UsersRole.ENDORSEMENT_CREATOR ||
        data?.data.user.role === UsersRole.POLICY_CREATOR
      ) {
        localStorage.setItem('currentUrl', 'dashboard');
      } else if (data?.data.user.role === UsersRole.PRIMARY_HR) {
        localStorage.setItem('currentUrl', 'hr-dashboard');
      } else if (data?.data.user.role === UsersRole.SECONDARY_HR) {
        localStorage.setItem('currentUrl', 'hrdashboard');
      }
      yield setItem('access_token', data.data.accessToken);
      yield setItem('logedIn', true);
      yield put(loginSuccess(true));
      yield toast.success('Logged In Sucessfully', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(logoutErr(data.message));
    }
  } catch (err) {
    yield put(loginErr(err));
  } finally {
    yield stopLoader('transparent');
  }
}
function* employeeRegisterHandler(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(employeeRegisterApi, values?.payload);
    if (data.status) {
      yield put(registerRedirection(data.status));
      yield localStorage.setItem('mobile_access_token', data.data.token);

      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield put(setMetaParams(data.meta_params));
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(logoutErr(data.message));
    }
  } catch (err) {
    yield put(loginErr(err));
  } finally {
    yield stopLoader('transparent');
  }
}

function* verifyOtpHandler(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(verifyOtpApi, values?.payload);
    if (data.status) {
      yield put(otpRedirection(data.status));
      yield put(isMpinCreated(data?.data?.isMpinCreated));

      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield put(setMetaParams(data.meta_params));
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(logoutErr(data.message));
    }
  } catch (err) {
    yield put(loginErr(err));
  } finally {
    yield stopLoader('transparent');
  }
}
function* createMpinHandler(values) {
  yield startLoader('transparent');

  try {
    const { data } = yield call(createMpinApi, values?.payload);
    if (data.status) {
      yield put(createMpinRedirection(data.status));
    } else {
      yield put(setMetaParams(data.meta_params));

      yield put(logoutErr(data.message));
    }
  } catch (err) {
    yield put(loginErr(err));
  } finally {
    yield stopLoader('transparent');
  }
}

function* employeeLoginHandler(values) {
  yield startLoader('transparent');
  yield put(loginBegin());
  yield delay(100);
  try {
    const { data } = yield call(employeeLoginApi, values?.payload?.data);

    if (data?.status) {
      yield put(employeeLoginRedirection(true));

      yield put(setUser(data?.data?.user));
      if (data?.data.user.role === UsersRole.EMPLOYEE) {
        localStorage.setItem('currentUrl', 'policies');
      }
      yield setItem('access_token', data.data.accessToken);
      yield localStorage.setItem('role', data.data.user.role);
      yield setItem('logedIn', true);
      yield put(loginSuccess(true));
      yield toast.success('Logged In Successfully', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield put(setMetaParams(data.meta_params));

      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(logoutErr(data.message));
    }
  } catch (err) {
    yield put(loginErr(err));
  } finally {
    yield stopLoader('transparent');
  }
}

function* logOut() {
  yield startLoader('transparent');

  yield put(logoutBegin());
  try {
    const { data } = yield call(logoutApi);
    if (data.status) {
      yield put(logoutSuccess(false));
      // yield localStorage.clear();
      yield removeItem('access_token');
      yield localStorage.removeItem('currentUrl');
      yield removeItem('pathName');
      yield removeItem('logedIn');
      // yield put(resetAllData());
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield put(logoutErr(false));
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield put(logoutErr(err));
    yield toast.error(err.response.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}

function* forgotPasswordHandler(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(forgotPasswordApi, values.payload);
    if (data.status) {
      if (values.payload.stage === '1') {
        yield put(emailVerified(true));
      }
      if (values.payload.stage === '2') {
        yield put(otpVerified(data?.data.verifyToken));
      }
      if (values.payload.stage === '3') {
        yield put(forgotPasswordSuccess(true));
      }
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield put(forgotPasswordError(true));
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield put(forgotPasswordError(true));
    yield toast.error(err.response.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}

function* changePasswordHandler(values) {
  yield startLoader('transparent');

  try {
    const { data } = yield call(changePasswordApi, values.data);
    if (data.status) {
      yield put(changePasswordSuccess(true));
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield logOut();
    } else {
      yield put(changePasswordError(true));
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield put(changePasswordError(true));
    yield toast.error(err.response.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}

function* dashboardKpiHandler(values) {
  yield startLoader('transparent');

  try {
    const { data } = yield call(dashboardKpiApi, values.payload);
    if (data?.status) {
      yield put(setKpi(data.data));
    } else {
      yield put(setMetaParams(data.meta_params));
    }
  } catch (err) {
    yield toast.error('Something went wrong', {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}

function* getExportPortfolioHandler(values) {
  yield startLoader('transparent');

  try {
    const { data } = yield call(getExportPortfolioApi, values.payload);
    if (data?.status) {
      yield xlsFileConverter(data);
    } else {
      yield put(setMetaParams(data.meta_params));
    }
  } catch (err) {
    yield toast.error('Something went wrong', {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}
export default function* userSaga() {
  yield takeLatest(types.START_LOGIN, loginHandler);
  yield takeLatest(types.START_LOGOUT, logOut);
  yield takeLatest(types.FORGOT_PASSWORD, forgotPasswordHandler);
  yield takeLatest(types.CHANGE_PASSWORD, changePasswordHandler);
  yield takeLatest(types.EMPLOYEE_REGISTER, employeeRegisterHandler);
  yield takeLatest(types.VERIFY_OTP, verifyOtpHandler);
  yield takeLatest(types.CREATE_MPIN, createMpinHandler);
  yield takeLatest(types.EMPLOYEE_LOGIN, employeeLoginHandler);
  yield takeLatest(types.GET_KPI, dashboardKpiHandler);
  yield takeLatest(types.GET_EXPORT_PORTFOLIO, getExportPortfolioHandler);
}
