/* eslint-disable import/no-cycle */
import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as types from '../../wellnessOrganization/actionTypes';
import { startLoader, stopLoader } from '../../../utility/commonFunction';
import {
  setWellnessOrganization,
  setWellnessDashboard,
  getWellnessOrganizationSuccess,
  getWellnessOrganizationFailure,
  setMetaParams,
  addWellnessOrganizationSuccess,
  addWellnessOrganizationError,
  getWellnessDashboardFailure,
  setWellnessPackageList,
  // getWellnessPackageListSuccess,
  getWellnessPackageListFailure,
  setWellnessDoctorConsultationData,
  getWellnessDoctorConsultationDataFailure,
  getWellnessDoctorConsultationDataSuccess,
  setWellnessDoctorConsultationExcelData,
  getWellnessDoctorConsultationExcelDataSuccess,
  getWellnessDoctorConsultationExcelDataFailure,
  addWellnessPolicySuccess,
  getWellnessCdOverviewSuccess,
  setWellnessCdOverview,
  getWellnessCdOverviewFailure,
} from '../../wellnessOrganization/actions';
import {
  getWellnessOrganizationApi,
  createWellnessProgramApi,
  getSosListApi,
  getWellnessDashboardApi,
  getWellnessPackageListApi,
  getWellnessFormDataApi,
  addWellnessFormExcelDataApi,
  addWellnessPolicyMembrApi,
  getWellnessCdOverViewApi,
  getWellnessCdOverviewByTypeApi,
  getWellnessCdOverviewExcelFileApi,
  addWellnessPolicyMembrWithFileApi,
} from '../requests/wellnessRequest';

function* getWellnessOrganizationHandler(action) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(getWellnessOrganizationApi, action.payload);
    if (data?.status) {
      yield put(setMetaParams(data.meta_params));
      yield put(setWellnessOrganization(data.data));
      yield put(getWellnessOrganizationSuccess());
    } else {
      yield put(setMetaParams([]));
      yield put(getWellnessOrganizationFailure());
    }
  } catch (err) {
    yield put(getWellnessOrganizationFailure(err.message || 'Something went wrong'));
    // console.log('error');
  } finally {
    yield stopLoader('transparent');
  }
}
function* addWellnessOrganizationHandler(value) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(createWellnessProgramApi, value?.data);
    if (data?.status) {
      yield put(addWellnessOrganizationSuccess(data));
      toast.success('Wellness organization added successfully!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(addWellnessOrganizationError(data.message));
    }
  } catch (err) {
    yield put(addWellnessOrganizationError(err));
    toast.error('An error occurred while adding the wellness organization.', {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}
function* getSosListHandler(action) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(getSosListApi, action.payload);
    if (data?.status) {
      yield put(setMetaParams(data.meta_params));
      yield put(setWellnessOrganization(data.data));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(getWellnessOrganizationFailure(data.message));
    }
  } catch (err) {
    yield put(getWellnessOrganizationFailure(err));
  } finally {
    yield stopLoader('transparent');
  }
}

function* getWellnessDashboardHandler(action) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(getWellnessDashboardApi, action.payload);
    if (data?.status) {
      yield put(setMetaParams(data.meta_params));
      yield put(setWellnessDashboard(data.data));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(getWellnessDashboardFailure(data.message));
    }
  } catch (err) {
    yield put(getWellnessDashboardFailure(err));
  } finally {
    yield stopLoader('transparent');
  }
}

function* getWellnessPackageListHandler() {
  yield startLoader('transparent');
  try {
    const { data } = yield call(getWellnessPackageListApi, {});
    if (data?.status) {
      yield put(setMetaParams(data.meta_params));
      yield put(setWellnessPackageList(data.data));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(getWellnessPackageListFailure(data.message));
    }
  } catch (err) {
    yield put(getWellnessPackageListFailure(err));
  } finally {
    yield stopLoader('transparent');
  }
}

function* getWellnessFormDataHandler(action) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(getWellnessFormDataApi, action.payload);
    if (data?.status) {
      yield put(setMetaParams(data.meta_params));
      yield put(setWellnessDoctorConsultationData(data.data));
      yield put(getWellnessDoctorConsultationDataSuccess());
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(getWellnessDoctorConsultationDataFailure(data.message));
    }
  } catch (err) {
    yield put(getWellnessPackageListFailure(err));
  } finally {
    yield stopLoader('transparent');
  }
}

function* addWellnessExcelFormDataHandler(action) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(addWellnessFormExcelDataApi, action.payload);
    if (data?.status) {
      yield put(setWellnessDoctorConsultationExcelData(data.data));
      yield put(getWellnessDoctorConsultationExcelDataSuccess());
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(getWellnessDoctorConsultationExcelDataFailure(data.message));
    }
  } catch (err) {
    yield put(getWellnessDoctorConsultationExcelDataFailure(err));
  } finally {
    yield stopLoader('transparent');
  }
}

function* addWellnessPolicyMemberHandler(action) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(addWellnessPolicyMembrApi, action.payload);
    if (data?.status) {
      yield put(addWellnessPolicySuccess(data.data));
      toast.success('Added successfully!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(getWellnessDoctorConsultationExcelDataFailure(data.message));
    }
  } catch (err) {
    yield put(getWellnessDoctorConsultationExcelDataFailure(err));
  } finally {
    yield stopLoader('transparent');
  }
}

function* addWellnessPolicyMemberWithFileHandler(action) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(addWellnessPolicyMembrWithFileApi, action.payload);
    if (data?.status) {
      yield put(addWellnessPolicySuccess(data.data));
      toast.success('Added successfully!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(getWellnessDoctorConsultationExcelDataFailure(data.message));
    }
  } catch (err) {
    yield put(getWellnessDoctorConsultationExcelDataFailure(err));
  } finally {
    yield stopLoader('transparent');
  }
}

function* getWellnessCdOverViewHandler(action) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(getWellnessCdOverViewApi, action.payload);
    if (data?.status) {
      yield put(setMetaParams(data.meta_params));
      yield put(setWellnessCdOverview(data.data));
      yield put(getWellnessCdOverviewSuccess());
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(getWellnessCdOverviewFailure(data.message));
    }
  } catch (err) {
    yield put(getWellnessCdOverviewFailure(err));
  } finally {
    yield stopLoader('transparent');
  }
}

function* getWellnessCdOverViewByTypeHandler(action) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(getWellnessCdOverviewByTypeApi, action.payload);
    if (data?.status) {
      yield put(setMetaParams(data.meta_params));
      yield put(setWellnessCdOverview(data.data));
      yield put(getWellnessCdOverviewSuccess());
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(getWellnessCdOverviewFailure(data.message));
    }
  } catch (err) {
    yield put(getWellnessCdOverviewFailure(err));
  } finally {
    yield stopLoader('transparent');
  }
}

function* getWellnessCdOverViewExcelDataHandler(action) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(getWellnessCdOverviewExcelFileApi, action.payload);
    if (data && data.file) {
      // Decode the base64 content
      const base64String = data.file;
      const binaryString = atob(base64String); // Decode base64 string to binary
      const len = binaryString.length;
      const bytes = new Uint8Array(len);

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      // Create a Blob and download the file
      const blob = new Blob([bytes], { type: 'application/vnd.ms-excel' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${data?.fileName}`); // Set the desired filename
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(getWellnessCdOverviewFailure(data.message));
    }
  } catch (err) {
    yield put(getWellnessCdOverviewFailure(err));
  } finally {
    yield stopLoader('transparent');
  }
}

export default function* wellnessSaga() {
  yield takeLatest(types.GET_WELLNESS_ORGANIZATION, getWellnessOrganizationHandler);
  yield takeLatest(types.ADD_WELLNESS_ORGANIZATION, addWellnessOrganizationHandler);
  yield takeLatest(types.GET_SOSLIST, getSosListHandler);
  yield takeLatest(types.GET_WELLNESS_DASHBOARD, getWellnessDashboardHandler);
  yield takeLatest(types.GET_WELLNESS_PACKAGE_LIST, getWellnessPackageListHandler);
  yield takeLatest(types.GET_WELLNESS_FORM_DATA, getWellnessFormDataHandler);
  yield takeLatest(types.GET_WELLNESS_EXCEL_FORM_DATA, addWellnessExcelFormDataHandler);
  yield takeLatest(types.ADD_WELLNESS_POLICY_MEMBER, addWellnessPolicyMemberHandler);
  yield takeLatest(types.GET_WELLNESS_CD_OVERVIEW, getWellnessCdOverViewHandler);
  yield takeLatest(types.GET_WELLNESS_CD_OVERVIEW_BY_TYPE, getWellnessCdOverViewByTypeHandler);
  yield takeLatest(types.GET_WELLNESS_CD_OVERVIEW_EXCEL_FILE, getWellnessCdOverViewExcelDataHandler);
  yield takeLatest(types.ADD_WELLNESS_POLICY_MEMBER_WITH_FILE, addWellnessPolicyMemberWithFileHandler);
}
