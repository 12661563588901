import * as types from './actionTypes';

const initialState = {
  loading: false,
  created: false,
  updated: false,
  allOrganization: [],
  allWellness: [],
  organizationDataById: [],
  organizationList: [],
  allEventDate: [],
  wellnessPackageData: [],
  metaParams: {},
  errors: {},
};

const OrganizationReducer = (state = initialState, action) => {
  const { type, data } = action;
  let addNewParams = {};
  switch (type) {
    case types.ADD_ORGANIZATION:
      return {
        ...state,
        loading: false,
      };
    case types.ADD_CALENDAR:
      return {
        ...state,
        loading: false,
      };
    case types.ADD_WELLNESS_ACTIVITY:
      return {
        ...state,
        loading: false,
      };
    case types.ADD_ORGANIZATION_SUCCESS:
      if (state.metaParams.pagination.total_count) {
        addNewParams = { ...state.metaParams.pagination, total_count: state.metaParams.pagination.total_count + 1 };
      }
      return {
        ...state,
        loading: false,
        created: true,
        allOrganization: [data.data, ...state.allOrganization],
        metaParams: { pagination: { ...addNewParams } },
      };
    case types.ADD_ORGANIZATION_ERROR:
      return {
        ...state,
        loading: false,
        created: false,
        errors: data?.formErrors,
      };
    case types.GET_ALL_ORGANIZATION:
      return {
        ...state,
        loading: true,
      };
    case types.GET_CALENDAR:
      return {
        ...state,
        loading: true,
      };
    case types.GET_WELLNESS_ACTIVITY_HISTORY:
      return {
        ...state,
        loading: true,
      };

    case types.SET_ALL_ORGANIZATION:
      return {
        ...state,
        loading: false,
        allOrganization: data,
      };
    case types.UPDATE_ORGANIZATION_SUCCESS: {
      let updatedData = '';
      if (action.data) {
        updatedData = state.allOrganization.map((item) => {
          if (item.id === action.data.id) {
            return action.data;
          }
          return item;
        });
      }
      return {
        ...state,
        loading: false,
        allOrganization: [...updatedData],
        updated: true,
      };
    }
    case types.UPDATE_ORGANIZATION_ERROR:
      return {
        ...state,
        loading: false,
        errors: data?.formErrors,
      };
    case types.GET_ORGANIZATION_BY_ID:
      return {
        ...state,
        loading: false,
      };
    case types.SET_ORGANIZATION_BY_ID:
      return {
        ...state,
        loading: false,
        organizationDataById: data,
      };
    case types.SET_META_PARAMS:
      return {
        ...state,
        loading: false,
        metaParams: action.payload,
      };
    case types.UPDATE_ORGANIZATION_RESET:
      return {
        ...state,
        loading: false,
        updated: false,
      };
    case types.SET_ORGANIZATION_LIST:
      return {
        ...state,
        organizationList: data,
      };
    case types.SET_WELLNESS_ACTIVITY_HISTORY:
      return {
        ...state,
        allWellness: data,
      };
    case types.GET_EVENT_DATE:
      return {
        ...state,
      };
    case types.GET_WELLNESS_PACKAGE:
      return {
        ...state,
      };
    case types.SET_WELLNESS_PACKAGE:
      return {
        ...state,
        wellnessPackageData: data,
      };

    case types.SET_ALL_EVENT_DATE:
      return {
        ...state,
        allEventDate: data,
      };
    default:
      return state;
  }
};

export default OrganizationReducer;
