import * as types from './actionTypes';

export const getWellnessOrganization = (data) => {
  return {
    type: types.GET_WELLNESS_ORGANIZATION,
    payload: data,
  };
};
export const setWellnessOrganization = (data) => {
  return {
    type: types.SET_WELLNESS_ORGANIZATION,
    payload: data,
  };
};
export const getWellnessOrganizationSuccess = () => {
  return {
    type: types.GET_WELLNESS_ORGANIZATION_SUCCESS,
  };
};
export const getWellnessOrganizationFailure = () => {
  return {
    type: types.GET_WELLNESS_ORGANIZATION_FAILURE,
  };
};
export const setMetaParams = (data) => {
  return {
    type: types.SET_META_PARAMS,
    payload: data,
  };
};
export const addWellnessOrganization = (data) => {
  return {
    type: types.ADD_WELLNESS_ORGANIZATION,
    data,
  };
};
export const addWellnessOrganizationError = (data) => {
  return {
    type: types.ADD_WELLNESS_ORGANIZATION_ERROR,
    data,
  };
};
export const addWellnessOrganizationSuccess = (data) => {
  return {
    type: types.ADD_WELLNESS_ORGANIZATION_SUCCESS,
    data,
  };
};
export const getSosList = (data) => {
  return {
    type: types.GET_SOSLIST,
    payload: data,
  };
};

export const getWellnessDashboard = (data) => {
  return {
    type: types.GET_WELLNESS_DASHBOARD,
    payload: data,
  };
};

export const setWellnessDashboard = (data) => {
  return {
    type: types.SET_WELLNESS_DASHBOARD,
    payload: data,
  };
};

export const getWellnessDashboardSuccess = () => {
  return {
    type: types.GET_WELLNESS_DASHBOARD_SUCCESS,
  };
};

export const getWellnessDashboardFailure = () => {
  return {
    type: types.GET_WELLNESS_DASHBOARD_FAILURE,
  };
};

// Wellness Package List

export const getWellnessPackageList = () => {
  return {
    type: types.GET_WELLNESS_PACKAGE_LIST,
  };
};

export const setWellnessPackageList = (data) => {
  return {
    type: types.SET_WELLNESS_PACKAGE_LIST,
    payload: data,
  };
};

export const getWellnessPackageListSuccess = () => {
  return {
    type: types.GET_WELLNESS_PACKAGE_LIST_SUCCESS,
  };
};

export const getWellnessPackageListFailure = () => {
  return {
    type: types.GET_WELLNESS_PACKAGE_LIST_FAILURE,
  };
};

// Wellness Doctor Consulatation

export const getWellnessDoctorConsultationData = (data) => {
  return {
    type: types.GET_WELLNESS_FORM_DATA,
    payload: data,
  };
};

export const setWellnessDoctorConsultationData = (data) => {
  return {
    type: types.SET_WELLNESS_FORM_DATA,
    payload: data,
  };
};

export const getWellnessDoctorConsultationDataSuccess = () => {
  return {
    type: types.GET_WELLNESS_FORM_DATA_SUCCESS,
  };
};

export const getWellnessDoctorConsultationDataFailure = () => {
  return {
    type: types.GET_WELLNESS_FORM_DATA_FAILURE,
  };
};

export const addWellnessDoctorConsultationExcelData = (data) => {
  return {
    type: types.GET_WELLNESS_EXCEL_FORM_DATA,
    payload: data,
  };
};

export const setWellnessDoctorConsultationExcelData = (data) => {
  return {
    type: types.SET_WELLNESS_EXCEL_FORM_DATA,
    payload: data,
  };
};

export const getWellnessDoctorConsultationExcelDataSuccess = () => {
  return {
    type: types.GET_WELLNESS_EXCEL_FORM_DATA_SUCCESS,
  };
};

export const getWellnessDoctorConsultationExcelDataFailure = () => {
  return {
    type: types.GET_WELLNESS_EXCEL_FORM_DATA_FAILURE,
  };
};

export const addWellnessPolicyMember = (data) => {
  return {
    type: types.ADD_WELLNESS_POLICY_MEMBER,
    payload: data,
  };
};

export const addWellnessPolicySuccess = (data) => {
  return {
    type: types.ADD_WELLNESS_POLICY_MEMBER_SUCCESS,
    data,
  };
};

export const addWellnessPolicyFailure = () => {
  return {
    type: types.ADD_WELLNESS_POLICY_MEMBER_FAILURE,
  };
};

export const resetTheWellnessFormData = () => {
  return {
    type: types.RESET_WELLNESS_FORM_DATA,
  };
};
