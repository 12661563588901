/* eslint-disable import/no-cycle */
import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as types from '../../wellnessOrganization/actionTypes';
import { startLoader, stopLoader } from '../../../utility/commonFunction';
import {
  setWellnessOrganization,
  setWellnessDashboard,
  getWellnessOrganizationSuccess,
  getWellnessOrganizationFailure,
  setMetaParams,
  addWellnessOrganizationSuccess,
  addWellnessOrganizationError,
  getWellnessDashboardFailure,
  setWellnessPackageList,
  // getWellnessPackageListSuccess,
  getWellnessPackageListFailure,
  setWellnessDoctorConsultationData,
  getWellnessDoctorConsultationDataFailure,
  getWellnessDoctorConsultationDataSuccess,
  setWellnessDoctorConsultationExcelData,
  getWellnessDoctorConsultationExcelDataSuccess,
  getWellnessDoctorConsultationExcelDataFailure,
  addWellnessPolicySuccess,
} from '../../wellnessOrganization/actions';
import {
  getWellnessOrganizationApi,
  createWellnessProgramApi,
  getSosListApi,
  getWellnessDashboardApi,
  getWellnessPackageListApi,
  getWellnessFormDataApi,
  addWellnessFormExcelDataApi,
  addWellnessPolicyMembrApi,
} from '../requests/wellnessRequest';

function* getWellnessOrganizationHandler(action) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(getWellnessOrganizationApi, action.payload);
    if (data?.status) {
      yield put(setMetaParams(data.meta_params));
      yield put(setWellnessOrganization(data.data));
      yield put(getWellnessOrganizationSuccess());
    } else {
      yield put(setMetaParams([]));
      yield put(getWellnessOrganizationFailure());
    }
  } catch (err) {
    yield put(getWellnessOrganizationFailure(err.message || 'Something went wrong'));
    // console.log('error');
  } finally {
    yield stopLoader('transparent');
  }
}
function* addWellnessOrganizationHandler(value) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(createWellnessProgramApi, value?.data);
    if (data?.status) {
      yield put(addWellnessOrganizationSuccess(data));
      toast.success('Wellness organization added successfully!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(addWellnessOrganizationError(data.message));
    }
  } catch (err) {
    yield put(addWellnessOrganizationError(err));
    toast.error('An error occurred while adding the wellness organization.', {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}
function* getSosListHandler(action) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(getSosListApi, action.payload);
    if (data?.status) {
      yield put(setMetaParams(data.meta_params));
      yield put(setWellnessOrganization(data.data));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(getWellnessOrganizationFailure(data.message));
    }
  } catch (err) {
    yield put(getWellnessOrganizationFailure(err));
  } finally {
    yield stopLoader('transparent');
  }
}

function* getWellnessDashboardHandler(action) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(getWellnessDashboardApi, action.payload);
    if (data?.status) {
      yield put(setMetaParams(data.meta_params));
      yield put(setWellnessDashboard(data.data));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(getWellnessDashboardFailure(data.message));
    }
  } catch (err) {
    yield put(getWellnessDashboardFailure(err));
  } finally {
    yield stopLoader('transparent');
  }
}

function* getWellnessPackageListHandler() {
  yield startLoader('transparent');
  try {
    const { data } = yield call(getWellnessPackageListApi, {});
    if (data?.status) {
      yield put(setMetaParams(data.meta_params));
      yield put(setWellnessPackageList(data.data));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(getWellnessPackageListFailure(data.message));
    }
  } catch (err) {
    yield put(getWellnessPackageListFailure(err));
  } finally {
    yield stopLoader('transparent');
  }
}

function* getWellnessFormDataHandler(action) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(getWellnessFormDataApi, action.payload);
    if (data?.status) {
      yield put(setMetaParams(data.meta_params));
      yield put(setWellnessDoctorConsultationData(data.data));
      yield put(getWellnessDoctorConsultationDataSuccess());
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(getWellnessDoctorConsultationDataFailure(data.message));
    }
  } catch (err) {
    yield put(getWellnessPackageListFailure(err));
  } finally {
    yield stopLoader('transparent');
  }
}

function* addWellnessExcelFormDataHandler(action) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(addWellnessFormExcelDataApi, action.payload);
    if (data?.status) {
      yield put(setWellnessDoctorConsultationExcelData(data.data));
      yield put(getWellnessDoctorConsultationExcelDataSuccess());
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(getWellnessDoctorConsultationExcelDataFailure(data.message));
    }
  } catch (err) {
    yield put(getWellnessDoctorConsultationExcelDataFailure(err));
  } finally {
    yield stopLoader('transparent');
  }
}

function* addWellnessPolicyMemberHandler(action) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(addWellnessPolicyMembrApi, action.payload);
    if (data?.status) {
      yield put(addWellnessPolicySuccess(data.data));
      toast.success('Added successfully!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(getWellnessDoctorConsultationExcelDataFailure(data.message));
    }
  } catch (err) {
    yield put(getWellnessDoctorConsultationExcelDataFailure(err));
  } finally {
    yield stopLoader('transparent');
  }
}

export default function* wellnessSaga() {
  yield takeLatest(types.GET_WELLNESS_ORGANIZATION, getWellnessOrganizationHandler);
  yield takeLatest(types.ADD_WELLNESS_ORGANIZATION, addWellnessOrganizationHandler);
  yield takeLatest(types.GET_SOSLIST, getSosListHandler);
  yield takeLatest(types.GET_WELLNESS_DASHBOARD, getWellnessDashboardHandler);
  yield takeLatest(types.GET_WELLNESS_PACKAGE_LIST, getWellnessPackageListHandler);
  yield takeLatest(types.GET_WELLNESS_FORM_DATA, getWellnessFormDataHandler);
  yield takeLatest(types.GET_WELLNESS_EXCEL_FORM_DATA, addWellnessExcelFormDataHandler);
  yield takeLatest(types.ADD_WELLNESS_POLICY_MEMBER, addWellnessPolicyMemberHandler);
}
