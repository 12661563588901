/* eslint-disable import/no-cycle */
import { DataService } from '../../../config/dataService/dataService';

export const getAllEndorsementApi = (value) => {
  if (value.policyType) {
    return DataService.get(
      `endorsement?search=${value.search}&page=${value.page}&policyType=${value.policyType}&type=${value.type}&policyId=${value.policyId}&month=${value.month}&tableRole=${value.tableRole}&policyActive=${value.policyActive} `,
      // `endorsement?page=${value.page}&policyType=${value.policyType}&type=${value.type}&policyId=${value.policyId}&month=${value.month}&tableRole=${value.tableRole} `,
    );
  }
  return DataService.get(
    `endorsement?page=${value.page}&type=${value.type}&policyId=${value.policyId}&month=${value.month}&tableRole=${value.tableRole} `,
  );

  // if (value.filter === undefined || value.policyId === undefined || value.policyType === undefined) {

  // }
};

export const deletionEndorsementApi = (value) => {
  return DataService.post(`endorsement/delete`, value);
};

export const relationEndorsementApi = (id) => {
  return DataService.get(`endorsement/relation?policyId=${id}`);
};
export const getExportsEndorsementApi = (value) => {
  return DataService.get(`endorsement/export?month=${value.monthFilter}&policyId=${value.policyNumberRoute}`);
};

export const getAdminExportsEndorsementApi = (value) => {
  return DataService.get(
    `endorsement/admin-export?page=${value.page}&policyType=${value.policyType}&type=${value.type}&policyId=${value.policyId}&month=${value.month}&tableRole=${value.tableRole}&policyActive=${value.policyActive} `,
  );
};

export const addEndorsementApi = (value) => {
  const formData = new FormData();
  formData.append('policyId', value.policyId);
  formData.append('gender', value.gender);
  formData.append('dateOfBirth', value.dateOfBirth);
  formData.append('memberName', value.memberName);
  formData.append('relation', value.relation);
  formData.append('document', value.document);
  formData.append('dateOfJoining', value.dateOfJoining);

  return DataService.post(`endorsement`, formData);
};

export const correctionEndorsementApi = (value) => {
  const formData = new FormData();
  formData.append('policyId', value.policyId);
  formData.append('memberId', value.memberId);
  formData.append('correctionType', value.correctionType);
  formData.append('correctionValue', value.correctionValue);
  formData.append('document', value.file);

  return DataService.post(`endorsement/correction`, formData);
};

export const statusUpdateEndorsementApi = (value) => {
  return DataService.put(`endorsement/${value.id}`, {
    status: value.status,
    remarks: value.remarks,
    dateOfJoining: value.dateOfJoining,
    contactNumber: value.contactNumber,
    emailId: value.emailId,
    dateOfLeaving: value.dateOfLeaving,
  });
};

// new API
export const updateEndorApi = (value) => {
  const formData = new FormData();
  formData.append('status', 1);
  formData.append('amount', value.amount);
  formData.append('invoice', value.invoice); // File object
  formData.append('invoice1', value.invoice1); // File object
  formData.append('endId', value.endId);
  formData.append('employee_code', value.employee_code);
  formData.append('_method', 'PUT');

  return DataService.post(`endorsement/approved/${value.endId}`, formData);
};

// new API end

export const addSelfEndorsementByHrApi = (value) => {
  const formData = new FormData();

  formData.append('policyId', value.policyId);
  formData.append('csvFile', value.document);

  return DataService.post(`endorsement/csv/upload`, formData);
};

export const getSelfEndorsementByHrApi = (value) => {
  return DataService.get(
    `employee/policy/${value.policyId}?search=${value.search}&page=${value.page}&searchIds=${value.selectedIDS}`,
  );
};
export const deleteSelfEndorsementByHrApi = (value) => {
  return DataService.post(`endorsement/bulk/delete`, value);
};

export const sendToRtfApi = (value) => {
  return DataService.post(`endorsement/bulk/update`, value);
};

export const getWellnessEndorsmentApi = (value) => {
  return DataService.get(
    `/wellnessendorsement/${value.orgId}?&page=${value.page}&types=${value.types}&month=${value.month}`,
  );
};
