export const GET_ALL_ENDORSEMENT = 'GET_ALL_ENDORSEMENT';
// export const GET_ALL_ADMIN_ENDORSEMENT = 'GET_ALL_ADMIN_ENDORSEMENT';
export const SET_ALL_ENDORSEMENT = 'SET_ALL_ENDORSEMENT';
export const SET_META_PARAMS = 'SET_META_PARAMS';
export const DELETION_ENDORSEMENT = 'DELETION_ENDORSEMENT';
export const SET_ENDORSEMENT_SUCCESS = 'SET_ENDORSEMENT_SUCCESS';
export const GET_RELATION_ENDORSEMENT = 'GET_RELATION_ENDORSEMENT';
export const SET_RELATION_DATA = 'SET_RELATION_DATA';
export const ADD_ENDORSEMENT = 'ADD_ENDORSEMENT';
export const CORRECTION_ENDORSEMENT = 'CORRECTION_ENDORSEMENT';
export const GET_EXPORTS_ENDORSEMENT = 'GET_EXPORTS_ENDORSEMENT';
export const GET_ADMIN_EXPORTS_ENDORSEMENT = 'GET_ADMIN_EXPORTS_ENDORSEMENT';
export const STATUS_UPDATE_ENDORSEMENT = 'STATUS_UPDATE_ENDORSEMENT';
export const NEW_STATUS_UPDATE_ENDORSEMENT = 'NEW_STATUS_UPDATE_ENDORSEMENT';

export const ADD_SELF_ENDORSEMENT_BY_HR = 'ADD_SELF_ENDORSEMENT_BY_HR';
export const DELETE_SELF_ENDORSEMENT_BY_HR = 'DELETE_SELF_ENDORSEMENT_BY_HR';
export const SET_DELETE_ENDORSEMENT_SUCCESS = 'SET_DELETE_ENDORSEMENT_SUCCESS';
export const GET_SELF_ENDORSEMENT_LIST_BY_HR = 'GET_SELF_ENDORSEMENT_LIST_BY_HR';
export const SELF_EMPLOYEE_LIST = 'SELF_EMPLOYEE_LIST';
export const SEND_TO_RTF = 'SEND_TO_RTF';
export const SET_SEND_TO_RTF_SUCCESS = 'SET_SEND_TO_RTF_SUCCESS';
export const SET_DELETE_META_PARAMS = 'SET_DELETE_META_PARAMS';

// Wellness Endorsment
export const GET_WELLNESS_ENDORSEMENT = 'GET_ALL_WELLNESS_ENDORSEMENT';
export const SET_WELLNESS_ENDORSEMENT = 'SET_ALL_WELLNESS_ENDORSEMENT';
export const SET_WELLNESS_ENDORSEMENT_SUCCESS = 'SET_WELLNESS_ENDORSEMENT_SUCCESS';
export const SET_WELLNESS_ENDORSEMENT_FAILURE = 'SET_WELLNESS_ENDORSEMENT_FAILURE';
