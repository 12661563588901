/* eslint-disable import/no-cycle */
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as types from '../../organization/actionTypes';
import {
  addOrganizationApi,
  getAllOrganizationApi,
  updateOrganizationApi,
  searchOrganizationApi,
  getOrganizationByIdApi,
  getNextPageOrganizationApi,
  getOrganizationListApi,
  getExportOrganizationApi,
  addCalendarApi,
  getCalendarApi,
  getwellnessActivityHistoryApi,
  addWellnessActivityApi,
  getEventDateApi,
  getwellnessPackagesApi,
  uploadEventRecordApi,
  wellnessHealthCheckupApi,
  wellnessDoctorConsultationApi,
} from '../requests/organizationRequests';
import {
  addOrganizationSuccess,
  setAllOrganization,
  addOrganizationError,
  updateOrganizationError,
  updateOrganizationSuccess,
  setOrganizationById,
  setOrganizationByIdError,
  setMetaParams,
  setOrganizationList,
  setEventDate,
} from '../../organization/actions';
import { startLoader, stopLoader } from '../../../utility/commonFunction';

function* addOrganizationHandler(values) {
  yield startLoader('transparent');

  try {
    const { data } = yield call(addOrganizationApi, values?.payload);
    if (data?.status) {
      yield put(addOrganizationSuccess({ status: true, data: data.data }));
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(addOrganizationError(data.meta_params));
    }
  } catch (err) {
    yield put(addOrganizationError(err));
  } finally {
    yield stopLoader('transparent');
  }
}

function* addCalendarHandler(values) {
  yield startLoader('transparent');

  try {
    const { data } = yield call(addCalendarApi, values?.payload);
    if (data?.status) {
      yield put(addOrganizationSuccess({ status: true, data: data.data }));
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(addOrganizationError(data.meta_params));
    }
  } catch (err) {
    yield put(addOrganizationError(err));
  } finally {
    yield stopLoader('transparent');
  }
}

function* addWellnessActivityHandler(values) {
  yield startLoader('transparent');

  try {
    const { data } = yield call(addWellnessActivityApi, values.payload);
    if (data?.status) {
      yield put(addOrganizationSuccess({ status: true, data: data.data }));
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(addOrganizationError(data.meta_params));
    }
  } catch (err) {
    yield put(addOrganizationError(err));
  } finally {
    yield stopLoader('transparent');
  }
}

function* getCalendarHandler(action) {
  yield startLoader('transparent');

  try {
    const { data } = yield call(getCalendarApi, action.payload);
    if (data?.status) {
      yield put(setMetaParams(data.meta_params));
      yield put(setAllOrganization(data.data));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(addOrganizationError(data.message));
    }
  } catch (err) {
    yield put(addOrganizationError(err));
  } finally {
    yield stopLoader('transparent');
  }
}

function* getwellnessActivityHistoryHandler(action) {
  yield startLoader('transparent');

  try {
    const { data } = yield call(getwellnessActivityHistoryApi, action.payload);
    if (data?.status) {
      yield put(setMetaParams(data.meta_params));
      yield put(setAllOrganization(data.data));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(addOrganizationError(data.message));
    }
  } catch (err) {
    yield put(addOrganizationError(err));
  } finally {
    yield stopLoader('transparent');
  }
}

function* updateOrganizationHandler(values) {
  yield startLoader('transparent');

  try {
    const { data } = yield call(updateOrganizationApi, values?.payload);
    if (data?.status) {
      yield put(updateOrganizationSuccess(data.data));
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(updateOrganizationError(data.meta_params));
    }
  } catch (err) {
    yield put(updateOrganizationError(err));
  } finally {
    yield stopLoader('transparent');
  }
}
function* getOrganizationHandler() {
  yield startLoader('transparent');

  try {
    const { data } = yield call(getAllOrganizationApi, {});
    if (data?.status) {
      yield put(setMetaParams(data.meta_params));
      yield put(setAllOrganization(data.data));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(addOrganizationError(data.message));
    }
  } catch (err) {
    yield put(addOrganizationError(err));
  } finally {
    yield stopLoader('transparent');
  }
}

function* searchOrganizationHandler(values) {
  yield delay(500);

  yield startLoader('transparent');

  try {
    const { data } = yield call(searchOrganizationApi, values?.payload);
    if (data?.status) {
      yield put(setAllOrganization(data.data));
      yield put(setMetaParams(data.meta_params));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(addOrganizationError(data.message));
    }
  } catch (err) {
    yield put(addOrganizationError(err));
  } finally {
    yield stopLoader('transparent');
  }
}

function* getOrganizationByIdHandler(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(getOrganizationByIdApi, values);
    if (data?.status) {
      yield put(setOrganizationById(data.data));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(setOrganizationByIdError(data.message));
    }
  } catch (err) {
    yield put(addOrganizationError(err));
  } finally {
    yield stopLoader('transparent');
  }
}
function* getNextOrganizationHandler(value) {
  yield startLoader('transparent');

  try {
    const { data } = yield call(getNextPageOrganizationApi, value?.payload);
    if (data?.status) {
      yield put(setMetaParams(data.meta_params));
      yield put(setAllOrganization(data.data));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(addOrganizationError(data.message));
    }
  } catch (err) {
    yield put(addOrganizationError(err));
  } finally {
    yield stopLoader('transparent');
  }
}

function* getOrganizationListHandler(value) {
  yield startLoader('transparent');

  try {
    const { data } = yield call(getOrganizationListApi, value);
    if (data?.status) {
      // console.log('data', data);
      // yield put(setOrganizationList(data.meta_params));
      yield put(setOrganizationList(data.data));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(addOrganizationError(data.message));
    }
  } catch (err) {
    yield put(addOrganizationError(err));
  } finally {
    yield stopLoader('transparent');
  }
}
function* getExportOrganizationHandler(action) {
  yield delay(100);
  try {
    const { data } = yield call(getExportOrganizationApi, action.payload);
    if (data?.status) {
      window.open(data.data, '_blank');
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}
function* getAllEventDateHandler() {
  yield startLoader('transparent');

  try {
    const { data } = yield call(getEventDateApi);
    if (data?.status) {
      yield put(setEventDate(data.data));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(addOrganizationError(data.message));
    }
  } catch (err) {
    yield put(addOrganizationError(err));
  } finally {
    yield stopLoader('transparent');
  }
}
function* getwellnessPackagesHandler() {
  yield startLoader('transparent');

  try {
    const { data } = yield call(getwellnessPackagesApi);
    if (data?.status) {
      yield put(setEventDate(data.data));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(addOrganizationError(data.message));
    }
  } catch (err) {
    yield put(addOrganizationError(err));
  } finally {
    yield stopLoader('transparent');
  }
}

function* uploadEventRecordHandler(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(uploadEventRecordApi, values.payload);
    if (data?.status) {
      // yield put(setEndorsementSuccess(true));
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err.response?.data.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}
function* wellnessHealthCheckupHandler(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(wellnessHealthCheckupApi, values.payload);
    if (data?.status) {
      // yield put(setEndorsementSuccess(true));
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err.response?.data.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}
function* wellnessDoctorConsultationHandler(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(wellnessDoctorConsultationApi, values.payload);
    if (data?.status) {
      // yield put(setEndorsementSuccess(true));
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err.response?.data.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}

export default function* insuranceSaga() {
  yield takeLatest(types.ADD_ORGANIZATION, addOrganizationHandler);
  yield takeLatest(types.UPDATE_ORGANIZATION, updateOrganizationHandler);
  yield takeLatest(types.GET_ALL_ORGANIZATION, getOrganizationHandler);
  yield takeLatest(types.SEARCH_ORGANIZATION, searchOrganizationHandler);
  yield takeLatest(types.GET_ORGANIZATION_BY_ID, getOrganizationByIdHandler);
  yield takeLatest(types.NEXT_PAGE_ORGANIZATION, getNextOrganizationHandler);
  yield takeLatest(types.GET_ORGANIZATION_LIST, getOrganizationListHandler);
  yield takeLatest(types.GET_EXPORT_ORGANIZATION, getExportOrganizationHandler);
  yield takeLatest(types.ADD_CALENDAR, addCalendarHandler);
  yield takeLatest(types.GET_CALENDAR, getCalendarHandler);
  yield takeLatest(types.GET_WELLNESS_ACTIVITY_HISTORY, getwellnessActivityHistoryHandler);
  yield takeLatest(types.ADD_WELLNESS_ACTIVITY, addWellnessActivityHandler);
  yield takeLatest(types.GET_EVENT_DATE, getAllEventDateHandler);
  yield takeLatest(types.GET_WELLNESS_PACKAGE, getwellnessPackagesHandler);
  yield takeLatest(types.UPLOAD_EVENT_RECORD, uploadEventRecordHandler);
  yield takeLatest(types.WELLNESS_HEALTH_CHECKUP, wellnessHealthCheckupHandler);
  yield takeLatest(types.WELLNESS_DOCTOR_CONSULTATION, wellnessDoctorConsultationHandler);
}
