import * as types from './actionTypes';

const initialState = {
  loading: false,
  wellnessOrganization: [],
  addWellnessOrganizations: [],
  wellnessFormData: {},
  wellnessExcelFormData: null,
  wellnessDashboard: {},
  wellnessPackage: {},
  errors: {},
  metaParams: {},
};

console.log('Data From The Reducer :', initialState.wellnessExcelFormData);
const wellnessReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case types.GET_WELLNESS_ORGANIZATION:
      return {
        ...state,
        loading: true,
      };
    case types.SET_WELLNESS_ORGANIZATION:
      return {
        ...state,
        wellnessOrganization: action.payload,
      };
    case types.GET_WELLNESS_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case types.GET_WELLNESS_ORGANIZATION_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.error,
      };
    case types.SET_META_PARAMS:
      return {
        ...state,
        loading: false,
        metaParams: action.payload,
      };
    case types.ADD_WELLNESS_ORGANIZATION:
      return {
        ...state,
        loading: false,
        addWellnessOrganizations: data,
      };
    case types.ADD_WELLNESS_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        created: data,
        wellnessOrganizationSuccess: true,
      };

    case types.ADD_WELLNESS_ORGANIZATION_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.error,
        wellnessOrganizationSuccess: false,
      };
    // SOS LIST
    case types.GET_SOSLIST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_WELLNESS_DASHBOARD:
      return {
        ...state,
        loading: true,
      };
    case types.SET_WELLNESS_DASHBOARD:
      return {
        ...state,
        wellnessDashboard: action.payload,
      };
    case types.GET_WELLNESS_DASHBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.GET_WELLNESS_DASHBOARD_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.error,
      };

    // Wellness PackageList
    case types.GET_WELLNESS_PACKAGE_LIST:
      return {
        ...state,
        loading: true,
      };
    case types.SET_WELLNESS_PACKAGE_LIST:
      return {
        ...state,
        wellnessPackage: action.payload,
      };
    case types.GET_WELLNESS_PACKAGE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.GET_WELLNESS_PACKAGE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.error,
      };
    // Wellness Doctor Consulatation
    case types.GET_WELLNESS_FORM_DATA:
      return {
        ...state,
        loading: true,
      };
    case types.SET_WELLNESS_FORM_DATA:
      return {
        ...state,
        wellnessFormData: action.payload,
      };
    case types.GET_WELLNESS_FORM_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.GET_WELLNESS_FORM_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.error,
      };

    case types.GET_WELLNESS_EXCEL_FORM_DATA:
      return {
        ...state,
        loading: true,
      };
    case types.SET_WELLNESS_EXCEL_FORM_DATA:
      return {
        ...state,
        wellnessExcelFormData: action.payload,
      };
    case types.GET_WELLNESS_EXCEL_FORM_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.GET_WELLNESS_EXCEL_FORM_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.error,
      };
    case types.ADD_WELLNESS_POLICY_MEMBER:
      return {
        ...state,
        loading: false,
      };
    case types.ADD_WELLNESS_POLICY_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.ADD_WELLNESS_POLICY_MEMBER_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case types.RESET_WELLNESS_FORM_DATA:
      return {
        ...state,
        wellnessExcelFormData: null,
      };

    default:
      return state;
  }
};

export default wellnessReducer;
