/* eslint-disable react/no-unused-prop-types */
import {
  UilCreateDashboard,
  UilBuilding,
  UilBriefcaseAlt,
  // UilMoneyWithdrawal,
  UilShieldPlus,
  UilNotes,
} from '@iconscout/react-unicons';
// import { LuClipboardList } from 'react-icons/lu';
import { HiUserGroup } from 'react-icons/hi2';
import { IoDocumentTextSharp } from 'react-icons/io5';
import { RiTodoFill, RiLockPasswordFill, RiHealthBookLine } from 'react-icons/ri';
import { MdDashboard, MdOutlineEventAvailable } from 'react-icons/md';
import { FaSignOutAlt } from 'react-icons/fa';
import { Image, Menu } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';

import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import propTypes from 'prop-types';
import { TbSos, TbHealthRecognition, TbPackages } from 'react-icons/tb';
import LogoMob from '../static/img/assest/logo_mob.png';
import LogoDesk from '../static/img/assest/logo_desk.png';
import Profile from '../static/img/assest/profile.jpg';
import { UsersRole, availableSideBarRoutes } from '../utility/utility';
import { startLogOut } from '../redux/authentication/actions';
// import { setActiveUrl } from '../redux/authentication/actions';
// import { NavTitle } from './Style';

function MenuItems({ collapsed }) {
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });
  const role = useSelector((state) => state?.auth.user?.role);
  const id = useSelector((state) => state?.auth.user?.organization?.id);

  const [selectedKeys, setSelectedKeys] = useState([localStorage.getItem('currentUrl') || '']);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const SignOut = (e) => {
    e.preventDefault();
    dispatch(startLogOut());
    setSelectedKeys([]);
    if (role === 'employee') {
      navigate('/employee-login');
      localStorage.removeItem('role');
    } else {
      navigate('/');
    }
  };
  const { t } = useTranslation();
  const path = '/';

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const hrRole = role === UsersRole.PRIMARY_HR || role === UsersRole.SECONDARY_HR;
  // eslint-disable-next-line no-unused-vars
  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'development'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  function handleClick(route) {
    // dispatch(setActiveUrl(route));
    localStorage.setItem('currentUrl', route);
    setSelectedKeys([route]);
  }
  // const currentUrl = useSelector((state) => state.auth?.activeUrl);
  // const currentUrl = localStorage.getItem('currentUrl');

  const handleRedirect = (route, redirect) => {
    handleClick(route);
    navigate(redirect);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const employeeItem = [
    getItem(
      <NavLink onClick={() => handleClick(availableSideBarRoutes.POLICIES)} to="/policies">
        {t('My Policies')}
      </NavLink>,
      'policies',
      !topMenu && <UilBuilding onClick={() => handleRedirect(availableSideBarRoutes.POLICIES, '/policies')} />,
    ),
  ];

  // for Claim Creator && Endorsement Creator
  const claimEndorsementItem = [
    getItem(
      <NavLink onClick={() => handleClick(availableSideBarRoutes.DASHBOARD)} to="/dashboard">
        {t('dashboard')}
      </NavLink>,
      'dashboard',
      !topMenu && <UilCreateDashboard onClick={() => handleRedirect(availableSideBarRoutes.DASHBOARD, '/dashboard')} />,
    ),
    getItem(
      <NavLink onClick={() => handleClick(availableSideBarRoutes.ORGANIZATION)} to="/organizations">
        {t('Organizations')}
      </NavLink>,
      'organizations',
      !topMenu && <UilBuilding onClick={() => handleRedirect(availableSideBarRoutes.ORGANIZATION, '/organizations')} />,
    ),
  ];
  // const primaryItem = getItem(
  //   <NavLink onClick={() => handleClick(availableSideBarRoutes.HR_DASHBOARD)} to={`/hr-dashboard/${id}`}>
  //     {t('dashboard')}
  //   </NavLink>,
  //   'hr-dashboard',
  //   !topMenu && (
  //     <UilBuilding onClick={() => handleRedirect(availableSideBarRoutes.HR_DASHBOARD, `/hr-dashboard/${id}`)} />
  //   ),
  // );
  const primaryItem = [
    getItem(
      <Image
        src={LogoDesk}
        className={collapsed ? 'display' : 'hr-sidebar-logo-desk'}
        style={{
          width: '150px',
          height: '50px',
          justifyItems: 'center',
          objectFit: 'cover',
        }}
        preview={false}
      />,
      ``,
      !topMenu && (
        <Image
          src={LogoMob}
          className={collapsed ? '' : 'display'}
          style={{
            width: '40px',
            height: '40px',
            justifyItems: 'center',
          }}
          preview={false}
        />
      ),
    ),
    role === UsersRole.PRIMARY_HR &&
      getItem(
        <NavLink onClick={() => handleClick(availableSideBarRoutes.HR_DASHBOARD)} to={`/hr-dashboard/${id}`}>
          {t('dashboard')}
        </NavLink>,
        'hr-dashboard',
        !topMenu && (
          <MdDashboard onClick={() => handleRedirect(availableSideBarRoutes.HR_DASHBOARD, `/hr-dashboard/${id}`)} />
        ),
      ),
    getItem(
      <NavLink onClick={() => handleClick(availableSideBarRoutes.WELLNES)} to={`/wellness-dashboard/${id}`}>
        {t('Wellness')}
      </NavLink>,
      'wellness',
      !topMenu && (
        <TbHealthRecognition
          onClick={() => handleRedirect(availableSideBarRoutes.WELLNES, `/wellness-dashboard/${id}`)}
        />
      ),
    ),
    // role === UsersRole.SECONDARY_HR && getItem(),
    // <NavLink onClick={() => handleClick(availableSideBarRoutes.SECONDARY_HR_DASHBOARD)} to={`/hrdashboard/${id}`}>
    //   {t('dashboard')}
    // </NavLink>,
    // 'hrdashboard',
    // !topMenu && (
    //   <MdDashboard
    //     onClick={() => handleRedirect(availableSideBarRoutes.SECONDARY_HR_DASHBOARD, `/hrdashboard/${id}`)}
    //   />
    // ),
    getItem(
      <NavLink onClick={() => handleClick(availableSideBarRoutes.HR_POLICIES)} to={`/organizations/${id}/policy`}>
        {t('Policies')}
      </NavLink>,
      'policy',
      !topMenu && (
        <RiTodoFill onClick={() => handleRedirect(availableSideBarRoutes.HR_POLICIES, `/organizations/${id}/policy`)} />
      ),
    ),
    getItem(
      <NavLink onClick={() => handleClick(availableSideBarRoutes.CLAIMS)} to={`/organizations/${id}/claim`}>
        {t('Claims')}
      </NavLink>,
      'claim',
      !topMenu && (
        <IoDocumentTextSharp
          onClick={() => handleRedirect(availableSideBarRoutes.CLAIMS, `/organizations/${id}/claim`)}
        />
      ),
    ),
    // role !== UsersRole.SECONDARY_HR &&
    //   getItem(
    //     <NavLink
    //       onClick={() => handleClick(availableSideBarRoutes.CD_STATEMENTS)}
    //       to={`/organizations/${id}/cd-statement`}
    //     >
    //       {t('CD statements')}
    //     </NavLink>,
    //     'cd-statements',
    //     !topMenu && (
    //       <FaCreditCard
    //         onClick={() => handleRedirect(availableSideBarRoutes.CD_STATEMENTS, `/organizations/${id}/cd-statement`)}
    //       />
    //     ),
    //   ),
    getItem(
      <NavLink onClick={() => handleClick(availableSideBarRoutes.HRS)} to={`/organizations/${id}/HRs`}>
        {t('HRs')}
      </NavLink>,
      'HRs',
      !topMenu && (
        <HiUserGroup onClick={() => handleRedirect(availableSideBarRoutes.HRS, `/organizations/${id}/HRs`)} />
      ),
    ),
    getItem(
      <NavLink onClick={() => handleClick(availableSideBarRoutes.EVENTS)} to={`/organizations/${id}/events`}>
        {t('Events')}
      </NavLink>,
      'Events',
      !topMenu && (
        <MdOutlineEventAvailable
          onClick={() => handleRedirect(availableSideBarRoutes.EVENTS, `/organizations/${id}/events`)}
        />
      ),
    ),
    getItem(
      <NavLink onClick={() => handleClick(availableSideBarRoutes.PACKAGES)} to={`/organizations/${id}/wellnesspackage`}>
        {t('Packages')}
      </NavLink>,
      'Packages',
      !topMenu && (
        <TbPackages
          onClick={() => handleRedirect(availableSideBarRoutes.PACKAGES, `/organizations/${id}/wellnesspackage`)}
        />
      ),
    ),
  ];

  const nonEmployeeItems = [
    getItem(
      <NavLink onClick={() => handleClick(availableSideBarRoutes.DASHBOARD)} to="/dashboard">
        {t('dashboard')}
      </NavLink>,
      'dashboard',
      !topMenu && <UilCreateDashboard onClick={() => handleRedirect(availableSideBarRoutes.DASHBOARD, '/dashboard')} />,
    ),
    getItem(
      <NavLink onClick={() => handleClick(availableSideBarRoutes.WELLNESS_DASHBOARD)} to="/wellness-dashboard">
        {t('Wellness Dashboard')}
      </NavLink>,
      'Wellness Dashboard',
      !topMenu && (
        <TbHealthRecognition
          onClick={() => handleRedirect(availableSideBarRoutes.WELLNESS_DASHBOARD, '/wellness-dashboard')}
        />
      ),
    ),
    getItem(
      <NavLink onClick={() => handleClick(availableSideBarRoutes.TPAs)} to="/tpas">
        {t('TPAs')}
      </NavLink>,
      'tpas',
      !topMenu && <UilBriefcaseAlt onClick={() => handleRedirect(availableSideBarRoutes.TPAs, '/tpas')} />,
    ),
    getItem(
      <NavLink onClick={() => handleClick(availableSideBarRoutes.INSURANCE_COMPANIES)} to="/insurance-companies">
        {t('Insurance Companies')}
      </NavLink>,
      'insurance-companies',
      !topMenu && (
        <UilShieldPlus
          onClick={() => handleRedirect(availableSideBarRoutes.INSURANCE_COMPANIES, '/insurance-companies')}
        />
      ),
    ),
    getItem(
      <NavLink onClick={() => handleClick(availableSideBarRoutes.ORGANIZATION)} to="/organizations">
        {t('Organizations')}
      </NavLink>,
      'organizations',
      !topMenu && <UilBuilding onClick={() => handleRedirect(availableSideBarRoutes.ORGANIZATION, '/organizations')} />,
    ),

    getItem(
      <NavLink onClick={() => handleClick(availableSideBarRoutes.RFQs)} to="/rfqs">
        {t('RFQs')}
      </NavLink>,
      'rfqs',
      !topMenu && <UilBuilding onClick={() => handleRedirect(availableSideBarRoutes.RFQs, '/rfqs')} />,
    ),

    getItem(
      <NavLink onClick={() => handleClick(availableSideBarRoutes.INSTRUCTIONS)} to="/instructions">
        {t('Instructions')}
      </NavLink>,
      'Instructions',
      !topMenu && <UilNotes onClick={() => handleRedirect(availableSideBarRoutes.INSTRUCTIONS, '/instructions')} />,
    ),
    getItem(
      <NavLink onClick={() => handleClick(availableSideBarRoutes.LIST)} to="/wellness-organization">
        {t('Wellness')}
      </NavLink>,
      'Wellness',
      !topMenu && (
        <RiHealthBookLine onClick={() => handleRedirect(availableSideBarRoutes.LIST, '/wellness-organization')} />
      ),
    ),
    getItem(
      <NavLink onClick={() => handleClick(availableSideBarRoutes.EVENTS)} to="/dashboard/event-list">
        {t('Events')}
      </NavLink>,
      'Events',
      !topMenu && (
        <MdOutlineEventAvailable
          onClick={() => handleRedirect(availableSideBarRoutes.EVENTS, `/dashboard/event-list`)}
        />
      ),
    ),
    getItem(
      <NavLink onClick={() => handleClick(availableSideBarRoutes.SOS)} to="/sos-list">
        {t('SOS')}
      </NavLink>,
      'SOS',
      !topMenu && <TbSos onClick={() => handleRedirect(availableSideBarRoutes.SOS, '/sos-list')} />,
    ),
  ];

  const primaryItemBottom = [
    getItem(
      ``,
      ``,
      !topMenu && (
        <Image
          src={Profile}
          style={{
            width: '40px',
            height: '40px',
            borderRadius: '10px',
            justifyItems: 'center',
            cursor: 'default',
          }}
          preview={false}
        />
      ),
    ),
    getItem(
      <NavLink
        className="hover-effect"
        style={{ paddingLeft: '7px' }}
        onClick={() => handleClick(availableSideBarRoutes.CHANGE_PASSWORD)}
        to="/resetPassword"
      >
        {t('Change Password')}
      </NavLink>,
      'change-password',
      !topMenu && (
        <RiLockPasswordFill onClick={() => handleRedirect(availableSideBarRoutes.CHANGE_PASSWORD, '/resetPassword')} />
      ),
    ),
    getItem(
      <NavLink to="/" onClick={SignOut}>
        {t('Signout')}
      </NavLink>,
      'Signout',
      !topMenu && <FaSignOutAlt onClick={SignOut} />,
    ),
  ];

  const items =
    role === UsersRole.EMPLOYEE
      ? employeeItem
      : role === UsersRole.PRIMARY_HR || role === UsersRole.SECONDARY_HR
      ? primaryItem
      : role === UsersRole.CLAIM_CREATOR || role === UsersRole.ENDORSEMENT_CREATOR
      ? claimEndorsementItem
      : nonEmployeeItems;

  // getItem(
  //   <NavLink onClick={toggleCollapsed} to="/claims">
  //     {t('claims')}
  //   </NavLink>,
  //   'claims',
  //   !topMenu && <UilMoneyWithdrawal />,
  // ),

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <Menu
          onOpenChange={onOpenChange}
          onClick={onClick}
          mode="inline"
          overflowedIndicator={<UilEllipsisV />}
          items={items}
          selectedKeys={selectedKeys}
          className={hrRole ? 'hr-dashboard-menu' : ''}
          style={{
            paddingLeft: hrRole && !collapsed ? '20px' : '0px',
          }}
        />

        {hrRole && (
          <Menu
            onOpenChange={onOpenChange}
            onClick={onClick}
            mode="inline"
            overflowedIndicator={<UilEllipsisV />}
            items={primaryItemBottom}
            selectedKeys={selectedKeys}
            className={collapsed ? 'hr-dash-menu-bottom ' : 'hr-dash-menu-bottom'}
            style={{
              paddingLeft: collapsed ? '' : '20px',
            }}
          />
        )}
      </div>
    </>
  );
}

MenuItems.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
  events: propTypes.object,
  collapsed: propTypes.bool,
};

export default MenuItems;
