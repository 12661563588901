export const ADD_ORGANIZATION = 'ADD_ORGANIZATION';
export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';
export const GET_ALL_ORGANIZATION = 'GET_ALL_ORGANIZATION';
export const SEARCH_ORGANIZATION = 'SEARCH_ORGANIZATION';
export const SET_ALL_ORGANIZATION = 'SET_ALL_ORGANIZATION';
export const ADD_ORGANIZATION_SUCCESS = 'ADD_ORGANIZATION_SUCCESS';
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS';
export const UPDATE_ORGANIZATION_ERROR = 'UPDATE_ORGANIZATION_ERROR';
export const ADD_ORGANIZATION_ERROR = 'ADD_ORGANIZATION_ERROR';
export const GET_ORGANIZATION_BY_ID = 'GET_ORGANIZATION_BY_ID';
export const SET_ORGANIZATION_BY_ID = 'SET_ORGANIZATION_BY_ID';
export const GET_ORGANIZATION_BY_ID_ERROR = 'GET_ORGANIZATION_BY_ID_ERROR';
export const NEXT_PAGE_ORGANIZATION = 'NEXT_PAGE_ORGANIZATION';
export const SET_META_PARAMS = 'SET_META_PARAMS';
export const UPDATE_ORGANIZATION_RESET = 'UPDATE_ORGANIZATION_RESET';
export const GET_ORGANIZATION_LIST = 'GET_ORGANIZATION_LIST';
export const SET_ORGANIZATION_LIST = 'SET_ORGANIZATION_LIST';
export const GET_EXPORT_ORGANIZATION = 'GET_EXPORT_ORGANIZATION';
export const ADD_CALENDAR = 'ADD_CALENDAR';
export const GET_CALENDAR = 'GET_CALENDAR';
export const GET_WELLNESS_ACTIVITY_HISTORY = 'GET_WELLNESS_ACTIVITY_HISTORY';
export const SET_WELLNESS_ACTIVITY_HISTORY = 'SET_WELLNESS_ACTIVITY_HISTORY';
export const SET_WELLNESS_PACKAGE = 'SET_WELLNESS_PACKAGE';
export const ADD_WELLNESS_ACTIVITY = 'ADD_WELLNESS_ACTIVITY';
export const GET_EVENT_DATE = 'GET_EVENT_DATE';
export const SET_ALL_EVENT_DATE = 'SET_ALL_EVENT_DATE';
export const GET_WELLNESS_PACKAGE = 'GET_WELLNESS_PACKAGE';
export const UPLOAD_EVENT_RECORD = 'UPLOAD_EVENT_RECORD';
export const WELLNESS_HEALTH_CHECKUP = 'WELLNESS_HEALTH_CHECKUP';
export const WELLNESS_DOCTOR_CONSULTATION = 'WELLNESS_DOCTOR_CONSULTATION';
