import * as types from './actionTypes';

export const addOrganization = (data) => {
  return {
    type: types.ADD_ORGANIZATION,
    payload: data,
  };
};
export const updateOrganization = (data) => {
  return {
    type: types.UPDATE_ORGANIZATION,
    payload: data,
  };
};
export const addOrganizationSuccess = (data) => {
  return {
    type: types.ADD_ORGANIZATION_SUCCESS,
    data,
  };
};

export const getAllOrganization = (data) => {
  return {
    type: types.GET_ALL_ORGANIZATION,
    data,
  };
};

export const searchOrganization = (data) => {
  return {
    type: types.SEARCH_ORGANIZATION,
    payload: data,
  };
};
export const setAllOrganization = (data) => {
  return {
    type: types.SET_ALL_ORGANIZATION,
    data,
  };
};
export const addOrganizationError = (data) => {
  return {
    type: types.ADD_ORGANIZATION_ERROR,
    data,
  };
};
export const updateOrganizationSuccess = (data) => {
  return {
    type: types.UPDATE_ORGANIZATION_SUCCESS,
    data,
  };
};
export const updateOrganizationError = (data) => {
  return {
    type: types.UPDATE_ORGANIZATION_ERROR,
    data,
  };
};
export const setMetaParams = (data) => {
  return {
    type: types.SET_META_PARAMS,
    payload: data,
  };
};
export const getNextPageOrganization = (data) => {
  return {
    type: types.NEXT_PAGE_ORGANIZATION,
    payload: data,
  };
};
export const getOrganizationbyId = (data) => {
  return {
    type: types.GET_ORGANIZATION_BY_ID,
    data,
  };
};
export const setOrganizationById = (data) => {
  return {
    type: types.SET_ORGANIZATION_BY_ID,
    data,
  };
};
export const setOrganizationByIdError = (data) => {
  return {
    type: types.GET_ORGANIZATION_BY_ID_ERROR,
    data,
  };
};

export const resetUpdateOrganization = (data) => {
  return {
    type: types.UPDATE_ORGANIZATION_RESET,
    payload: data,
  };
};

export const getOrganizationList = (data) => {
  return {
    type: types.GET_ORGANIZATION_LIST,
    data,
  };
};

export const setOrganizationList = (data) => {
  return {
    type: types.SET_ORGANIZATION_LIST,
    data,
  };
};
export const setWellnessActivityHistory = (data) => {
  return {
    type: types.SET_WELLNESS_ACTIVITY_HISTORY,
    data,
  };
};
export const setWellnessPackage = (data) => {
  return {
    type: types.SET_WELLNESS_PACKAGE,
    data,
  };
};

export const getExportsOrganization = (data) => {
  return {
    type: types.GET_EXPORT_ORGANIZATION,
    payload: data,
  };
};

export const addCalendar = (data) => {
  return {
    type: types.ADD_CALENDAR,
    payload: data,
  };
};

export const getCalendar = (data) => {
  return {
    type: types.GET_CALENDAR,
    payload: data,
  };
};

export const getWellnessActivityHistory = (data) => {
  return {
    type: types.GET_WELLNESS_ACTIVITY_HISTORY,
    payload: data,
  };
};

export const addWellnessActivity = (data) => {
  return {
    type: types.ADD_WELLNESS_ACTIVITY,
    payload: data,
  };
};

export const getEventDate = () => {
  return {
    type: types.GET_EVENT_DATE,
  };
};

export const setEventDate = (data) => {
  return {
    type: types.SET_ALL_EVENT_DATE,
    data,
  };
};
export const getWellnessPackage = () => {
  return {
    type: types.GET_WELLNESS_PACKAGE,
  };
};

export const uploadEventRecord = (data) => {
  return {
    type: types.UPLOAD_EVENT_RECORD,
    payload: data,
  };
};

export const wellnessHealthCheckup = (data) => {
  return {
    type: types.WELLNESS_HEALTH_CHECKUP,
    payload: data,
  };
};

export const wellnessDoctorConsultation = (data) => {
  return {
    type: types.WELLNESS_DOCTOR_CONSULTATION,
    payload: data,
  };
};
