/* eslint-disable import/no-cycle */
import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
// import * as types from '../../cd/actionTypes';
import * as types from '../../cdStatement/actionTypes';

import { startLoader, stopLoader } from '../../../utility/commonFunction';
import {
  addCdApi,
  getAllCdApi,
  getExportCdApi,
  getExportCdWellnessApi,
  updateCdApi,
  addCdAtachmentApi,
  updateEndorApi,
  getAllCdListApi,
} from '../requests/cdRequests';
import {
  addCdSuccess,
  updateEndorSuccess,
  setAllCd,
  setMetaParams,
  updateCdSuccess,
  updateCdAttachmentSuccess,
  setAllCdList,
  setCdWellnessMetaParams,
} from '../../cdStatement/actions';
import { csvFileConverter } from '../../../utility/utility';

function* addCdHandler(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(addCdApi, values.payload);
    if (data?.status) {
      yield put(addCdSuccess(true));
      yield put(setMetaParams(data.meta_params));

      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield put(setMetaParams(data.meta_params));

      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err.response.data.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}
function* updateCdHandler(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(updateCdApi, values.payload);
    if (data?.status) {
      yield put(updateCdSuccess(true));
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err.response.data.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}

// new action
// function* addEndorHandler(values) {
//   yield startLoader('transparent');
//   try {
//     const { data } = yield call(updateEndorApi, values.payload);
//     if (data?.status) {
//       yield put(updateEndorSuccess(true));
//       yield put(setMetaParams(data.meta_params));

//       yield toast.success(data.message, {
//         position: toast.POSITION.TOP_RIGHT,
//       });
//     } else {
//       yield put(setMetaParams(data.meta_params));

//       yield toast.error(data.message, {
//         position: toast.POSITION.TOP_RIGHT,
//       });
//     }
//   } catch (err) {
//     yield toast.error(err.response.data.message, {
//       position: toast.POSITION.TOP_RIGHT,
//     });
//   } finally {
//     yield stopLoader('transparent');
//   }
// }
export const addEndorHandler = async (values) => {
  try {
    // Start loader
    startLoader('transparent');
    // Call the API
    const { data } = await updateEndorApi(values.payload);

    // Handle success
    if (data?.status) {
      updateEndorSuccess(true);
      setMetaParams(data.meta_params);

      toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      setMetaParams(data.meta_params);

      toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    toast.error(err.response.data.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    // Stop loader
    stopLoader('transparent');
  }
};

// new action end

function* getCdHandler(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(getAllCdApi, values.payload);
    if (data?.status) {
      yield put(setAllCd(data.data));
      yield put(setMetaParams(data.meta_params));
    } else {
      yield put(setAllCd([]));
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err.response.data.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}
function* getExportCdHandler(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(getExportCdApi, values.payload);
    if (data?.status) {
      yield csvFileConverter(data.data);
      // yield put(setMetaParams(data.meta_params));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err.response.data.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}

function* getExportCdWellnessHandler(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(getExportCdWellnessApi, values.payload);
    if (data && data.file) {
      // Decode the base64 content
      const base64String = data.file;
      const binaryString = atob(base64String); // Decode base64 string to binary
      const len = binaryString.length;
      const bytes = new Uint8Array(len);

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      // Create a Blob and download the file
      const blob = new Blob([bytes], { type: 'application/vnd.ms-excel' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'data.xls'); // Set the desired filename
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } else {
      yield toast.error(data.message || 'No file found in response', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err.response?.data?.message || 'Error exporting data', {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}

function* addCDAtachment(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(addCdAtachmentApi, values.payload);
    if (data?.status) {
      yield put(updateCdAttachmentSuccess(true));
      yield put(setMetaParams(data.meta_params));

      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield put(setMetaParams(data.meta_params));

      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err.response.data.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}

function* getAllCdListHandler(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(getAllCdListApi, values.payload);
    if (data?.status) {
      yield put(setAllCdList(data.data));
      yield put(setCdWellnessMetaParams(data.meta_params));
    } else {
      yield put(setAllCdList([]));
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err.response.data.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}
export default function* cdSaga() {
  yield takeLatest(types.ADD_CD, addCdHandler);
  yield takeLatest(types.UPDATE_CD, updateCdHandler);
  yield takeLatest(types.UPDATE_ENDOR, addEndorHandler);

  yield takeLatest(types.GET_ALL_CD, getCdHandler);
  yield takeLatest(types.GET_EXPORT_CD, getExportCdHandler);
  yield takeLatest(types.GET_EXPORT_CD_WELLNESS, getExportCdWellnessHandler);
  yield takeLatest(types.ADD_CD_ENDT_STATEMENT, addCDAtachment);

  yield takeLatest(types.GET_ALL_CD_LIST, getAllCdListHandler);
}
