import * as types from './actionTypes';

export const addCd = (data) => {
  return {
    type: types.ADD_CD,
    payload: data,
  };
};

export const updateEndor = (data) => {
  return {
    type: types.UPDATE_ENDOR,
    payload: data,
  };
};

export const updateEndorSuccess = (data) => {
  return {
    type: types.UPDATE_ENDOR_SUCCESS,
    payload: data,
  };
};

export const addCdSuccess = (data) => {
  return {
    type: types.ADD_CD_SUCCESS,
    payload: data,
  };
};

export const getAllCd = (data) => {
  return {
    type: types.GET_ALL_CD,
    payload: data,
  };
};
export const setAllCd = (data) => {
  return {
    type: types.SET_ALL_CD,
    payload: data,
  };
};

export const setMetaParams = (data) => {
  return {
    type: types.SET_META_PARAMS,
    payload: data,
  };
};

export const updateCd = (data) => {
  return {
    type: types.UPDATE_CD,
    payload: data,
  };
};

export const updateCdSuccess = (data) => {
  return {
    type: types.UPDATE_CD_SUCCESS,
    payload: data,
  };
};

export const getExportsCD = (data) => {
  return {
    type: types.GET_EXPORT_CD,
    payload: data,
  };
};
export const getExportsCDWellness = (data) => {
  return {
    type: types.GET_EXPORT_CD_WELLNESS,
    payload: data,
  };
};

export const updateCdAttachmentSuccess = (data) => {
  return {
    type: types.ADD_CD_ENDT_STATEMENT,
    payload: data,
  };
};

export const getAllCdList = (data) => {
  return {
    type: types.GET_ALL_CD_LIST,
    payload: data,
  };
};

export const setAllCdList = (data) => {
  return {
    type: types.SET_ALL_CD_LIST,
    payload: data,
  };
};

export const setCdWellnessMetaParams = (data) => {
  return {
    type: types.SET_CD_WELLNESS_META_PARAMS,
    payload: data,
  };
};
