export const GET_WELLNESS_ORGANIZATION = 'GET_WELLNESS_ORGANIZATION';
export const SET_WELLNESS_ORGANIZATION = 'SET_WELLNESS_ORGANIZATION';
export const GET_WELLNESS_ORGANIZATION_SUCCESS = 'GET_WELLNESS_ORGANIZATION_SUCCESS';
export const GET_WELLNESS_ORGANIZATION_FAILURE = 'GET_WELLNESS_ORGANIZATION_FAILURE';
export const ADD_WELLNESS_ORGANIZATION = 'ADD_WELLNESS_ORGANIZATION';
export const ADD_WELLNESS_ORGANIZATION_ERROR = 'ADD_WELLNESS_ORGANIZATION_ERROR';
export const ADD_WELLNESS_ORGANIZATION_SUCCESS = 'ADD_WELLNESS_ORGANIZATION_SUCCESS';
export const SET_META_PARAMS = 'SET_META_PARAMS';
export const GET_SOSLIST = 'GET_SOSLIST';

// Wellness Dashboard
export const GET_WELLNESS_DASHBOARD = 'GET_WELLNESS_DASHBOARD';
export const SET_WELLNESS_DASHBOARD = 'SET_WELLNESS_DASHBOARD';
export const GET_WELLNESS_DASHBOARD_SUCCESS = 'GET_WELLNESS_DASHBOARD_SUCCESS';
export const GET_WELLNESS_DASHBOARD_FAILURE = 'GET_WELLNESS_DASHBOARD_FAILURE';

// Wellness Package
export const GET_WELLNESS_PACKAGE_LIST = 'GET_WELLNESS_PACKAGE_LIST';
export const SET_WELLNESS_PACKAGE_LIST = 'SET_WELLNESS_PACKAGE_LIST';
export const GET_WELLNESS_PACKAGE_LIST_SUCCESS = 'GET_WELLNESS_PACKAGE_LIST_SUCCESS';
export const GET_WELLNESS_PACKAGE_LIST_FAILURE = 'GET_WELLNESS_PACKAGE_LIST_FAILURE';

// Wellness Doctor Consulatation

export const GET_WELLNESS_FORM_DATA = 'GET_WELLNESS_FORM_DATA';
export const SET_WELLNESS_FORM_DATA = 'SET_WELLNESS_FORM_DATA';
export const GET_WELLNESS_FORM_DATA_SUCCESS = 'GET_WELLNESS_FORM_DATA_SUCCESS';
export const GET_WELLNESS_FORM_DATA_FAILURE = 'SET_WELLNESS_FORM_DATA_FAILURE';

export const GET_WELLNESS_EXCEL_FORM_DATA = 'GET_WELLNESS_EXCEL_FORM_DATA';
export const SET_WELLNESS_EXCEL_FORM_DATA = 'SET_WELLNESS_EXCEL_FORM_DATA';
export const GET_WELLNESS_EXCEL_FORM_DATA_SUCCESS = 'GET_WELLNESS_EXCEL_FORM_DATA_SUCCESS';
export const GET_WELLNESS_EXCEL_FORM_DATA_FAILURE = 'SET_WELLNESS_EXCEL_FORM_DATA_FAILURE';

// add wellness Policy Memeber

export const ADD_WELLNESS_POLICY_MEMBER = 'ADD_WELLNESS_POLICY_MEMBER';
export const ADD_WELLNESS_POLICY_MEMBER_SUCCESS = 'ADD_WELLNESS_POLICY_MEMBER_SUCCESS';
export const ADD_WELLNESS_POLICY_MEMBER_FAILURE = 'ADD_WELLNESS_POLICY_MEMBER_FAILURE';

export const RESET_WELLNESS_FORM_DATA = 'RESET_WELLNESS_FORM_DATA';
