/* eslint-disable import/no-cycle */
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as types from '../../endorsement/actionTypes';

import { startLoader, stopLoader } from '../../../utility/commonFunction';
import {
  setAllEndorsement,
  setDeleteMetaParams,
  // getAllAdminEndorsement,
  // setDeleteEndorsementSuccess,
  setEndorsementSuccess,
  setMetaParams,
  setRelationData,
  setSelfEmployeeList,
  setWellnessEndorsment,
} from '../../endorsement/action';
import {
  addEndorsementApi,
  addSelfEndorsementByHrApi,
  correctionEndorsementApi,
  deleteSelfEndorsementByHrApi,
  deletionEndorsementApi,
  getAllEndorsementApi,
  // getEndorAllEndorsementApi,
  getExportsEndorsementApi,
  getAdminExportsEndorsementApi,
  getSelfEndorsementByHrApi,
  relationEndorsementApi,
  sendToRtfApi,
  statusUpdateEndorsementApi,
  updateEndorApi,
  getWellnessEndorsmentApi,
} from '../requests/endorsementRequests';
import { csvFileConverter } from '../../../utility/utility';

function* getEndorsementHandler(values) {
  yield startLoader('transparent');
  yield delay(100);
  try {
    const { data } = yield call(getAllEndorsementApi, values.payload);
    if (data?.status) {
      yield put(setAllEndorsement(data.data));
      yield put(setMetaParams(data.meta_params));
    } else {
      yield put(setAllEndorsement());
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield put(setAllEndorsement());

    yield toast.error(err.response.data.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}

// function* getEndorHandler(values) {
//   yield startLoader('transparent');
//   yield delay(100);
//   try {
//     const { data } = yield call(getEndorAllEndorsementApi, values.payload);
//     if (data?.status) {
//       yield put(setAllEndorsement(data.data));
//       yield put(setMetaParams(data.meta_params));
//     } else {
//       yield put(setAllEndorsement());
//       yield toast.error(data.message, {
//         position: toast.POSITION.TOP_RIGHT,
//       });
//     }
//   } catch (err) {
//     yield put(setAllEndorsement());

//     yield toast.error(err.response.data.message, {
//       position: toast.POSITION.TOP_RIGHT,
//     });
//   } finally {
//     yield stopLoader('transparent');
//   }
// }

function* deletionEndorsementHandler(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(deletionEndorsementApi, values.payload);
    if (data?.status) {
      yield put(setEndorsementSuccess(true));
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err.response.data.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}

function* relationEndorsementHandler(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(relationEndorsementApi, values.payload);
    if (data?.status) {
      yield put(setRelationData(data.data));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}

function* getExportsEndorsementHandler(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(getExportsEndorsementApi, values.payload);
    if (data?.status) {
      yield csvFileConverter(data.data);
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}

function* getAdminExportsEndorsementHandler(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(getAdminExportsEndorsementApi, values.payload);
    if (data?.status) {
      yield csvFileConverter(data.data);
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}

function* addEndorsementHandler(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(addEndorsementApi, values.payload);
    if (data?.status) {
      yield put(setEndorsementSuccess(true));
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err.response.data.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}

function* correctionEndorsementHandler(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(correctionEndorsementApi, values.payload);
    if (data?.status) {
      yield put(setEndorsementSuccess(true));
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield put(setMetaParams(data.meta_params));
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err.response.data.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}
function* statusUpdateEndorsementHandler(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(statusUpdateEndorsementApi, values.payload);
    if (data?.status) {
      yield put(setEndorsementSuccess(true));
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err.response?.data.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}

// new
function* newStatusUpdateEndorsementHandler(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(updateEndorApi, values.payload);
    if (data?.status) {
      yield put(setEndorsementSuccess(true));
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err.response?.data.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}
// new end
function* addSelfEndorsementByHrHandler(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(addSelfEndorsementByHrApi, values.payload);
    if (data?.status) {
      yield put(setEndorsementSuccess(true));
      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield put(setMetaParams(data.meta_params));
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err.response.data.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}
function* getSelfEndorsementListByHrHandler(values) {
  yield startLoader('transparent');
  yield delay(500);

  try {
    const { data } = yield call(getSelfEndorsementByHrApi, values.payload);
    if (data?.status) {
      yield put(setSelfEmployeeList(data.data));
      yield put(setDeleteMetaParams(data.meta_params));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}
function* deleteSelfEndorsementListByHrHandler(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(deleteSelfEndorsementByHrApi, values.payload);
    if (data?.status) {
      yield put(setMetaParams(data.meta_params));
      // yield put(setDeleteEndorsementSuccess(true));
      yield put(setEndorsementSuccess(true));

      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield put(setMetaParams(data.meta_params));

      yield toast.error(data.meta_params?.formErrors?.employeeId, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err.response.data.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}
function* sendToRtfHandler(values) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(sendToRtfApi, values.payload);
    if (data?.status) {
      // yield put(setMetaParams(data.meta_params));
      yield put(setEndorsementSuccess(true));

      yield toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      // yield put(setMetaParams(data.meta_params));

      yield toast.error(data.meta_params?.formErrors?.employeeId, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err.response.data.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}
function* getWellnessEndorsementListByHrHandler(values) {
  yield startLoader('transparent');
  yield delay(500);

  try {
    const { data } = yield call(getWellnessEndorsmentApi, values.payload);
    if (data?.status) {
      yield put(setWellnessEndorsment(data.data));
      yield put(setMetaParams(data.meta_params));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield toast.error(err, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}
export default function* endorsementSaga() {
  yield takeLatest(types.ADD_ENDORSEMENT, addEndorsementHandler);
  yield takeLatest(types.GET_ALL_ENDORSEMENT, getEndorsementHandler);
  // yield takeLatest(types.GET_ALL_ADMIN_ENDORSEMENT, getEndorHandler);
  yield takeLatest(types.DELETION_ENDORSEMENT, deletionEndorsementHandler);
  yield takeLatest(types.CORRECTION_ENDORSEMENT, correctionEndorsementHandler);
  yield takeLatest(types.GET_RELATION_ENDORSEMENT, relationEndorsementHandler);
  yield takeLatest(types.GET_EXPORTS_ENDORSEMENT, getExportsEndorsementHandler);
  yield takeLatest(types.GET_ADMIN_EXPORTS_ENDORSEMENT, getAdminExportsEndorsementHandler);
  yield takeLatest(types.STATUS_UPDATE_ENDORSEMENT, statusUpdateEndorsementHandler);
  yield takeLatest(types.NEW_STATUS_UPDATE_ENDORSEMENT, newStatusUpdateEndorsementHandler);

  yield takeLatest(types.ADD_SELF_ENDORSEMENT_BY_HR, addSelfEndorsementByHrHandler);
  yield takeLatest(types.GET_SELF_ENDORSEMENT_LIST_BY_HR, getSelfEndorsementListByHrHandler);
  yield takeLatest(types.DELETE_SELF_ENDORSEMENT_BY_HR, deleteSelfEndorsementListByHrHandler);
  yield takeLatest(types.SEND_TO_RTF, sendToRtfHandler);
  yield takeLatest(types.GET_WELLNESS_ENDORSEMENT, getWellnessEndorsementListByHrHandler);
}
