/* eslint-disable import/no-cycle */
import { DataService2 } from '../../../config/dataService/dataServiceSecondary';
import { DataService } from '../../../config/dataService/dataService';

export const getWellnessOrganizationApi = (values) => {
  return DataService2.get(`/wellness?page=${values.page}&search=${values.search}`);
};
export const createWellnessProgramApi = (values) => {
  return DataService2.post(`/wellness`, values);
};
export const getSosListApi = (value) => {
  return DataService2.get(`/sos-users?page=${value.page}`);
};

export const getWellnessDashboardApi = (value) => {
  return DataService2.get(`/wellness-activity/wellnessDashboard/${value.id}`);
};

export const getWellnessPackageListApi = () => {
  return DataService2.get('/wellness-packages/packageListData');
};

// Wellness Doctor Consulatation
export const getWellnessFormDataApi = (value) => {
  return DataService.get(`policy/getExistEmpData/${value.id}`);
};

export const addWellnessFormExcelDataApi = (value) => {
  const formData = new FormData();
  formData.append('csvfile', value.csv);
  return DataService.post(`policy/wellnessUploadExcel`, formData);
};

export const addWellnessPolicyMembrApi = (value) => {
  const payload = {
    orgnizationId: value.orgnizationId,
    createdHrId: value.createdHrId,
    activityType: 'doctor_consultation',
    familyDefinition: value.familyDefinition,
    totalEmployee: value.totalEmployee,
    costPerPerson: value.costPerPerson,
    terms: value.terms,
    policy_member: value.policy_member,
    uploadedFile: value.uploadedFile,
  };
  return DataService.post(`policy/wellnessUploadMember/${payload.orgnizationId}`, payload);
};

export const addWellnessPolicyMembrWithFileApi = (value) => {
  const formData = new FormData();
  formData.append('orgnizationId', value.orgnizationId);
  formData.append('value.orgnizationId', value.createdHrId);
  formData.append('activityType', 'doctor_consultation');
  formData.append('familyDefinition', value.familyDefinition);
  formData.append('totalEmployee', value.totalEmployee);
  formData.append('costPerPerson', value.costPerPerson);
  formData.append('terms', value.terms);
  formData.append('policy_member', value.policy_member);
  formData.append('uploadedFile', value.uploadedFile);
  return DataService.post(`policy/wellnessUploadMemberWithFile/${value.orgnizationId}`, formData);
};

export const getWellnessCdOverViewApi = (value) => {
  return DataService2.get(`cd-statements?organizationId=${value.id}&page=${value.page}`);
};

export const getWellnessCdOverviewByTypeApi = (value) => {
  return DataService2.get(
    `cd-statements?organizationId=${value.id}&page=${value.page}&types=${value.types || '0'}&year=${
      value.year || '2025'
    }`,
  );
};

export const getWellnessCdOverviewExcelFileApi = (value) => {
  return DataService2.get(
    `cd-statements/cdStatementExcelDownload/${value.id}&year=${value.year || '2025'}&types=${value.types || '0'}`,
  );
};
